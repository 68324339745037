/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding initialization code.
 * Use "quasar new plugin <name>" and add it there.
 * One plugin per concern. Then reference the file(s) in quasar.conf.js > plugins:
 * plugins: ['file', ...] // do not add ".js" extension to it.
 **/

import iconSet from 'quasar-framework/icons/ionicons'


import Vue from 'vue'

import {Quasar,QLayout,QLayoutHeader,QLayoutFooter,QLayoutDrawer,QPageContainer,QPage,QToolbar,QTooltip,QToolbarTitle,QBtn,QIcon,QList,QListHeader,QItem,QItemMain,QItemSeparator,QItemSide,QItemTile,QTabs,QTab,QTabPane,QRouteTab,QInnerLoading,QSpinner,QSpinnerPuff,QSpinnerTail,QSpinnerBars,QTimeline,QTimelineEntry,QToggle,QCheckbox,QField,QInput,QPageSticky,QPullToRefresh,QAjaxBar,QKnob,QChip,QBtnDropdown,QModal,QModalLayout,QRadio,QSelect,QProgress,QCarousel,QCarouselSlide,QCarouselControl,QCard,QCardMedia,QCardTitle,QCardSeparator,QCardMain,QCardActions,QScrollObservable,QCollapsible,QBtnToggle,QBtnGroup,QFab,QFabAction,QAlert,GoBack,Ripple,TouchPan,ActionSheet,Dialog,Notify,LocalStorage,SessionStorage,Loading,Meta,AppVisibility} from 'quasar'


Vue.use(Quasar, { config: {"cordava":{"backButtonExit":false}},iconSet: iconSet,components: {QLayout,QLayoutHeader,QLayoutFooter,QLayoutDrawer,QPageContainer,QPage,QToolbar,QTooltip,QToolbarTitle,QBtn,QIcon,QList,QListHeader,QItem,QItemMain,QItemSeparator,QItemSide,QItemTile,QTabs,QTab,QTabPane,QRouteTab,QInnerLoading,QSpinner,QSpinnerPuff,QSpinnerTail,QSpinnerBars,QTimeline,QTimelineEntry,QToggle,QCheckbox,QField,QInput,QPageSticky,QPullToRefresh,QAjaxBar,QKnob,QChip,QBtnDropdown,QModal,QModalLayout,QRadio,QSelect,QProgress,QCarousel,QCarouselSlide,QCarouselControl,QCard,QCardMedia,QCardTitle,QCardSeparator,QCardMain,QCardActions,QScrollObservable,QCollapsible,QBtnToggle,QBtnGroup,QFab,QFabAction,QAlert},directives: {GoBack,Ripple,TouchPan},plugins: {ActionSheet,Dialog,Notify,LocalStorage,SessionStorage,Loading,Meta,AppVisibility} })
