export default ({ Vue }) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('## Custom service worker registered!', registration.scope)
      })
      .catch(err => {
        console.error('## SW registration failed', err)
      })
  }
}
