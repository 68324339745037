<template>
  <div v-if="ready">
    <!-- HEADER -->
    <q-layout-header id="productsEditHeader" class="opacity-0">
      <q-toolbar inverted style="min-height: 82px">
        <q-toolbar-title>
           <div class="text-center cursor-pointer" style="padding-top: 13px">
            <!-- <q-spinner-puff v-show="product.data.channel.online" color="gold" size="3em" class="absolute" style="z-index: 1; top: 0px; left: calc(50% - 1.5em + 0px)"/> -->
            <!-- <img :class="{ 'pulse': product.data.channel.online }" src="/statics/icons/icon-gold.svg" width="66" class="relative z-top"/> -->
            <!-- <img src="/statics/icons/icon-gold.svg" width="66" class="relative z-top"/> -->
            <span class="title-value text-family-brand text-weight-regular text-gold block">
              <sup class="block">&nbsp;</sup>
              <span class="text-subinfo text-weight-bold capitalize text-fixedwidth-brand">
                <transition mode="out-in" appear enter-active-class="animated slideInUp" leave-active-class="animated fadeOutDown">
                  <span key="chip-work" style="top: -11px; width:auto !important" v-if="/*!amIOnline &&*/ (!clients.length || channelFetchActiveClientsRequestsCount || channelFetchActiveClientsRequestActive)" class="relative-position uppercase chip-live chip-live-blue">
                    <q-icon color="white" name="ion-code-working"/>
                  </span>
                  <span key="chip-live" style="top: -11px; width:auto !important" v-else-if="amIOnline" class="relative-position uppercase chip-live">LIVE</span>
                  <span key="chip-offl" style="top: -11px; width:auto !important" v-else class="relative-position uppercase chip-offline">AWAY</span>
                  <!-- <span v-if="amIOnline" style="top: -11px; width:auto !important" class="relative-position uppercase chip-live" key="channel-status-live" >{{ $t('LIVE') }}</span> -->
                  <!-- <span v-else style="top: -11px; width:auto !important" class="relative-position uppercase chip-offline" key="channel-status-offline">AWAY</span> -->
                </transition>
              </span>
            </span>
          </div>
          <span slot="subtitle" class="text-weight-medium block" style="margin-top:10px">
            <span class="block text-system-brand text-weight-bold title-subject text-black no-text-transform overflow-hidden">
              {{ product.data.business.name }}
            </span>
            <span class="line-height-sm block text-system-brand text-weight-semibold text-subinfo uppercase overflow-hidden" v-html="product.data.business.address.html"></span>
          </span>
        </q-toolbar-title>
        <q-btn round size="lg" text-color="primary" class="absolute z-top" @click="drawerTriggerShow">
          <q-icon name="ion-arrow-forward"/>
        </q-btn>
        <!--
        <q-btn round size="lg" text-color="primary" class="absolute z-top" @click="exitRequest">
          <q-icon name="ion-arrow-up"/>
        </q-btn>
        -->
        <q-btn round size="lg" text-color="primary" class="absolute z-top" @click="dialogSettingsShowOpen">
          <q-icon name="ion-more"/>
        </q-btn>
      </q-toolbar>
    </q-layout-header>

    <!-- CONTENT -->
    <div ref="productsEditContent" id="productsEditContent" class="layout-padding no-pointer-events" style="padding-top: 0">
      <q-scroll-observable @scroll="scrolled"/>
      <div class="product">

        <!-- publishing debug button -->
        <div class="hidden row no-wrap items-center margin-auto-lr relative-position" style="max-width: 400px; z-index: 1">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="protect" text-color="white" rounded @click="channelRequestsClear">
                Reset &nbsp; Queue
              </q-btn>
            </q-card-main>
          </q-card>
        </div>

        <div style="margin-top: 50px"></div>

        <q-tabs color="gray" animated inverted style="margin-top: -60px; margin-bottom: 40px">
          <q-tab default slot="title" name="tab-today" icon="ion-pulse" style="margin: 0 10px">
            <span class="on-top-sm font-size-120p text-system-brand text-weight-semibold">Today</span>
          </q-tab>
          <q-tab slot="title" name="tab-services" icon="ion-ios-paper" style="margin: 0 10px">
            <span class="on-top-sm font-size-120p text-system-brand text-weight-semibold">Services</span>
          </q-tab>
          <q-tab :disable="isProduction" slot="title" name="tab-team" icon="ion-people" style="margin: 0 10px">
            <span class="on-top-sm font-size-120p text-system-brand text-weight-semibold">Team</span>
          </q-tab>

          <!-- TAB: TODAY -->
          <q-tab-pane name="tab-today" keep-alive>
            <div class="on-top-lg module-grid-layout-ignore">
            <masonry :cols="{default: 2, 680: 1}" :gutter="{ default: 30, 680: 15}">

              <!-- debug: active clients -->
              <q-list v-if="settings_dev_presense" class="text-system-brand text-weight-medium q-list-depth"
                style="max-width: 320px !important; margin: 30px auto !important;">
                <q-list-header class="no-margin-top text-weight-bold text-tertiary module-title-size">
                  <q-icon :color="amIOnline ? 'educate' : 'protect'" name="ion-information-circle" size="32px" class="on-left-sm"/> Active Clients
                </q-list-header>
                <q-item link tag="label" @click.native="channelFetchActiveClients()">
                  <q-item-side class="text-center">
                    <q-icon name="ion-repeat" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  </q-item-side>
                  <q-item-main>
                    <q-item-tile label>Fetch Clients</q-item-tile>
                    <q-item-tile sublabel>Refresh list of users</q-item-tile>
                  </q-item-main>
                </q-item>
                <q-item-separator/>
                <div v-if="clients.length">
                  <q-item item v-for="client in clients" :key="client.uuid">
                    <q-item-side class="text-center">
                      <q-icon v-if="client.state && client.state.role === 'admin'" name="ion-medal" color="educate" size="33px"/>
                      <q-icon v-else-if="client.uuid === product.data.uri" name="ion-star" color="gold" size="33px"/>
                      <q-icon v-else name="ion-contact" color="gray" size="33px"/>
                    </q-item-side>
                    <q-item-main>
                      <q-item-tile label>
                        {{ client.state && client.state.role === 'admin' ? 'Admin' : 'User' }}
                        <q-chip v-if="client.uuid === product.data.uri" dense>YOU</q-chip>
                      </q-item-tile>
                      <q-item-tile sublabel><q-chip dense>{{ client.uuid }}</q-chip></q-item-tile>
                      <!-- <q-item-tile sublabel v-if="client.state">{{ client.state.name ? client.state.name : (client.state.phone ? client.state.phone : '--') }}</q-item-tile> -->
                    </q-item-main>
                  </q-item>
                </div>
                <div v-else>
                  <p class="text-center">No clients</p>
                </div>
              </q-list>

              <!-- today -->
              <q-list highlight link class="text-system-brand text-weight-medium q-list-depth"
                style="max-width: 320px !important; margin: 30px auto !important;">
                <template v-if="today_day">
                  <q-progress v-if="product.data.business.hoo[today_day].is24" stripe indeterminate color="educate" height="4px" style="position:relative;top:-8px;left:0;margin-bottom:-4px"/>
                  <q-progress v-else-if="product.data.business.hoo[today_day].isClosed" :percentage="100" stripe color="protect" height="4px" style="position:relative;top:-8px;left:0;margin-bottom:-4px"/>
                  <q-progress v-else-if="today_time_elapsed >= 0 && today_time_elapsed < 100" :percentage="today_time_elapsed/1" stripe :buffer="100-(today_time_elapsed/1)" :color="(today_time_elapsed >= 90) ? 'attention' : 'educate'" height="4px" style="position:relative;top:-8px;left:0;margin-bottom:-4px"/>
                  <q-progress v-else :percentage="100" stripe color="protect" height="4px" style="position:relative;top:-8px;left:0;margin-bottom:-4px"/>
                </template>
                <q-list-header class="no-margin-top text-weight-bold text-secondary module-title-size">
                  <q-btn round size="18px" text-color="secondary" icon="ion-more" class="float-right" style="margin-top:-10px;margin-right:-10px" @click="moduleSettingsToday"/>
                  <q-icon name="ion-calendar" color="secondary" size="32px" class="on-left-sm"/> Today
                </q-list-header>
                <q-item-separator/>
                <q-item item link tag="label">
                  <q-item-side class="text-center">
                    <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                      <q-icon v-if="channel_online" key="channel-online-on" name="ion-radio" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px" class="pulse"/>
                      <q-icon v-else key="channel-online-off" name="ion-radio-button-on" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                    </transition>
                  </q-item-side>
                  <q-item-main>
                    <q-item-tile label>
                      <span v-if="product.data.business.hoo[today_day].is24" class="text-educate">Open</span>
                      <span v-else-if="product.data.business.hoo[today_day].isClosed" class="text-protect">Closed</span>
                      <span v-else-if="today_time_elapsed < 0 && today_time_elapsed > -10" class="text-educate">Opening Soon</span>
                      <span v-else-if="today_time_elapsed < 0 || today_time_elapsed > 100" class="text-protect">Closed</span>
                      <span v-else-if="today_time_elapsed >= 90" class="text-attention">Closing soon</span>
                      <span v-else class="text-educate">Open</span>
                    </q-item-tile>
                    <q-item-tile sublabel class="capitalize">
                      <template v-if="typeof channel_orders === undefined || channel_orders === 0 || (product.data.business.hoo[today_day].isClosed || (today_time_elapsed < 0 || today_time_elapsed > 100))">Standard Operations</template>
                      <span v-else class="text-protect text-weight-semibold">
                        {{ getStatusChangeData(channel_orders).label }}
                      </span>
                    </q-item-tile>
                  </q-item-main>
                  <q-item-side right>
                    <q-chip>{{ clients.length === 0 ? '•••' : clients.length - 1 }}</q-chip>
                  </q-item-side>
                  <q-item-side right @click.native="updateStatusChange()">
                    <q-icon v-if="channel_orders === 0 || (product.data.business.hoo[today_day].isClosed || (today_time_elapsed < 0 || today_time_elapsed > 100))" name="ion-checkmark-circle" color="educate" size="33px"/>
                    <img v-else :src="getStatusChangeData(channel_orders).icon" width="33">
                  </q-item-side>
                </q-item>
              </q-list>
              <!-- loyalty -->
              <q-list highlight link class="text-system-brand text-weight-medium q-list-depth"
                style="max-width: 320px !important; margin: 30px auto !important;">
                <transition mode="out-in" appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                <q-progress v-if="requests.length" stripe indeterminate color="empower" height="4px" style="position:relative;top:-8px;left:0;margin-bottom:-4px"/>
                </transition>
                <q-list-header class="no-margin-top text-weight-bold text-empower module-title-size">
                  <q-btn round size="18px" text-color="empower" icon="ion-more" class="float-right" style="margin-top:-10px;margin-right:-10px" @click="moduleSettingsLoyalty"/>
                  <q-icon name="ion-gift" size="32px" class="on-left-sm"/> Loyalty
                </q-list-header>
                <q-item-separator/>
                <!-- || !requests.length -->
                <q-item item link :disabled="!loyalty_enabled" @click.native="loyalty_enabled ? loyaltyEnterPIN() : false">
                  <q-item-side class="text-center">
                    <q-icon :name="loyalty_enabled ? 'ion-keypad' : 'ion-eye-off'" size="33px" :color="anyDarkmode ? '' : 'blue-grey-10'"/>
                  </q-item-side>
                  <q-item-main v-if="loyalty_enabled">
                    <q-item-tile label class="capitalize">Check PIN</q-item-tile>
                    <q-item-tile sublabel>From customer</q-item-tile>
                  </q-item-main>
                  <q-item-main v-else>
                    <q-item-tile label class="capitalize">Disabled</q-item-tile>
                    <q-item-tile sublabel>Tap ••• to enable</q-item-tile>
                  </q-item-main>
                  <q-item-side right>
                    <transition mode="out-in" appear enter-active-class="animated slideInRight" leave-active-class="animated slideOutRight">
                    <q-icon v-if="requests.length" name="ion-checkmark-circle" color="educate" size="33px"/>
                    </transition>
                  </q-item-side>
                </q-item>
              </q-list>
              </masonry>
            </div>
          </q-tab-pane>

          <!-- TAB: SERVICES -->
          <q-tab-pane name="tab-services" keep-alive>
            <div class="text-center" style="position: relative; z-index: 100;">
              <q-btn @click.native="serviceComponentCreate" class="text-weight-semibold inline-block text-secondary font-size-100p">
                <q-icon name="ion-pricetag" class="on-left-sm"/>
                <span class="text-system-brand">Create Service</span>
              </q-btn>
              <q-btn @click.native="serviceCreationReset(); dialogServicesAdd()" class="text-weight-semibold inline-block text-secondary font-size-100p">
                <q-icon name="ion-pricetags" class="on-left-sm"/>
                <span class="text-system-brand">Create Stack</span>
              </q-btn>
              <q-btn @click.native="dialogGroupsShow()" class="text-weight-semibold inline-block text-secondary font-size-100p">
                <q-icon name="ion-folder-open" class="on-left-sm"/>
                <span class="text-system-brand">Manage Groups</span>
              </q-btn>
              <q-btn class="text-weight-semibold inline-block text-secondary font-size-100p hidden">
                <q-icon name="ion-flask" class="on-left-sm"/>
                <span class="text-system-brand">Manage Workflows</span>
              </q-btn>
            </div>

            <!-- Spaces -->
            <div class="text-center">
              <q-tabs color="shallow" text-color="tertiary" animated style="margin-top: -33px; margin-bottom: 40px; position: relative; z-index: 0;" class="q-tabs-head-hide">
                <q-tab name="tab-space-0" slot="title" class="text-system-brand text-weight-semibold font-size-120p hidden" default>All</q-tab>
                <!-- <q-tab name="tab-space-1" slot="title" class="text-system-brand text-weight-semibold font-size-120p">1st Floor</q-tab> -->
                <!-- <q-tab name="tab-space-2" slot="title" class="text-system-brand text-weight-semibold font-size-120p">2nd Floor</q-tab> -->
                <!-- <q-btn @click.native="dialogSpacesManage" name="tab-space-n" slot="title" class="text-secondary on-right-xs"><span class="text-system-brand text-secondary text-weight-semibold font-size-100p">•••</span></q-btn> -->
                <!-- Spaces-Tabs -->
                <q-tab-pane name="tab-space-0" keep-alive style="padding-top: 20px">
                  <masonry :cols="{default: 3, 900: 2, 660: 1}" :gutter="{ default: 20, 680: 15}" v-if="product.data.business.services">
                    <!-- custom services -->
                    <q-list highlight link class="text-system-brand text-weight-medium q-list-depth"
                    v-if="productServicesGroupedByCategories['_']"
                    style="border-color: #a67c474d !important; max-width: 320px !important; margin: 30px auto !important;">
                      <q-list-header class="no-margin-top text-weight-bold text-value module-title-size text-left">
                        <q-chip class="float-right" text-color="subinfo">{{ Object.keys(productServicesGroupedByCategories['_']).length }}</q-chip>
                        <q-icon name="ion-pricetags" class="on-left-sm" size="32px"/>
                        Stacks
                      </q-list-header>
                      <q-item-separator/>
                      <q-item item link tag="label" v-for="service in productServicesGroupedByCategories['_']" :key="`service-${service.uuid}`" separator
                        @click.native="componentUpdate(service.uuid)">
                        <q-item-side class="text-center">
                          <img :src="`/statics/services/${product.data.business.components[service.icon].categoryId}.${product.data.business.components[service.icon].componentId}.svg`" width="33"/>
                          <q-icon v-if="service.private" color="protect" name="ion-eye-off" size="20px" class="absolute" style="top: 8px; left: 38px; height: 20px; border: 4px solid #fff; background: #fff; border-radius: 2em"/>
                        </q-item-side>
                        <q-item-main>
                          <q-item-tile label class="capitalize text-weight-semibold">
                            <div v-if="service.isNew" class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">NEW</div>
                            {{ service.label }}
                          </q-item-tile>
                          <q-item-tile v-if="service.dependencies && service.dependencies.length" sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="purple-pale" icon="ion-git-pull-request" dense>
                              {{ service.dependencies.length }} {{ service.dependencies.length === 1 ? 'dependency' : 'dependencies' }}
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-apps" dense>
                              {{ service.components.length }} features
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile v-if="service.refId" sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-grid" dense>
                              {{ service.refId }}
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-pulse" dense>
                              {{ serviceLastUpdate(service.uuid) | dtformat }}
                            </q-chip>
                          </q-item-tile>
                        </q-item-main>
                        <q-item-side right>
                          <q-icon v-if="serviceStatus(service.uuid) === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 1" name="ion-alert" color="attention" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 2" name="ion-close-circle" color="protect" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                          <q-icon v-else name="ion-code" color="shallow" size="32px"/>
                        </q-item-side>
                      </q-item>
                      <q-item-separator/>
                    </q-list>
                    <!-- built-in services -->
                    <q-list v-for="(services, serviceCategory) in productServicesGroupedByCategories"
                      v-if="serviceCategory !== '_'" :key="`${serviceCategory}`"
                      highlight link class="text-system-brand text-weight-medium q-list-depth"
                      style="max-width: 320px !important; margin: 30px auto !important"
                      >
                      <q-list-header class="no-margin-top text-weight-bold text-empower module-title-size text-left">
                        <q-chip class="float-right" text-color="subinfo">{{ Object.keys(services).length }}</q-chip>
                        <q-icon name="ion-pricetag" class="on-left-sm" size="32px"/>
                        {{ getServiceCategoryLabel(serviceCategory) }}
                      </q-list-header>
                      <template v-for="(servicesByComponent, componentId) in componentsGroupedByComponents(services)"
                        v-if="Wings.services.list[product.data.business.components[servicesByComponent[Object.keys(servicesByComponent)[0]].components[0]].categoryId]">
                      <q-item-separator/>
                      <q-list-header class="text-weight-bold text-grey text-left">
                        {{ Wings.services.list[product.data.business.components[servicesByComponent[Object.keys(servicesByComponent)[0]].components[0]].categoryId][componentId].name }}
                      </q-list-header>
                      <q-item item link tag="label" v-for="service in servicesByComponent" :key="`service-${service.uuid}`"
                        @click.native="componentUpdate(service.uuid)">
                        <q-item-side class="text-center">
                          <img :src="`/statics/services/${product.data.business.components[service.components[0]].categoryId}.${product.data.business.components[service.components[0]].componentId}.svg`" width="33"/>
                          <q-icon v-if="service.private" color="protect" name="ion-eye-off" size="20px" class="absolute" style="top: 8px; left: 38px; height: 20px; border: 4px solid #fff; background: #fff; border-radius: 2em"/>
                        </q-item-side>
                        <q-item-main>
                          <q-item-tile label class="capitalize text-weight-semibold">
                            <div v-if="service.isNew" class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">NEW</div>
                            {{ service.label }}
                            <span v-if="!service.label" class="text-shadow">{{ Wings.services.list[product.data.business.components[service.components[0]].categoryId][product.data.business.components[service.components[0]].componentId].name }}</span>
                          </q-item-tile>
                          <q-item-tile v-if="product.data.business.components[service.components[0]].isDev" sublabel>
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-swap" dense>
                              API
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile v-if="service.dependencies && service.dependencies.length" sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="purple-pale" icon="ion-git-pull-request" dense>
                              {{ service.dependencies.length }} {{ service.dependencies.length === 1 ? 'dependency' : 'dependencies' }}
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile v-if="service.refId" sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-grid" dense>
                              {{ service.refId }}
                            </q-chip>
                          </q-item-tile>
                          <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold">
                            <q-chip detail square class="q-chip-icon-dark text-weight-semibold q-chip-padded" color="shallower" icon="ion-pulse" dense>
                              {{ serviceLastUpdate(service.uuid) | dtformat }}
                            </q-chip>
                          </q-item-tile>
                        </q-item-main>
                        <q-item-side right @click.native.prevent.stop="changeComponentStatus(product.data.business.components[service.components[0]], service.uuid)">
                          <q-icon v-if="serviceStatus(service.uuid) === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 1" name="ion-alert" color="attention" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 2" name="ion-close-circle" color="protect" size="32px"/>
                          <q-icon v-else-if="serviceStatus(service.uuid) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                          <q-icon v-else name="ion-code" color="shallow" size="32px"/>
                        </q-item-side>
                      </q-item>
                      </template>
                    </q-list>
                  </masonry>
                </q-tab-pane>
                <q-tab-pane name="tab-space-1" keep-alive>
                  <p class="on-top-xx text-system-brand text-weight-semibold text-subinfo-l">No Services</p>
                </q-tab-pane>
                <q-tab-pane name="tab-space-2" keep-alive>
                  <p class="on-top-xx text-system-brand text-weight-semibold text-subinfo-l">No Services</p>
                </q-tab-pane>
              </q-tabs>
            </div>
          </q-tab-pane>

          <!-- TAB: TEAM -->
          <q-tab-pane name="tab-team" keep-alive>
            <div class="text-center">
              <q-btn class="text-weight-semibold inline-block text-secondary font-size-100p">
                <q-icon name="ion-add-circle" class="on-left-sm"/>
                <span class="text-system-brand">Add User</span>
              </q-btn>
            </div>
            <div class="on-top-lg module-grid-layout">
              <!-- Users -->
              <q-list highlight link class="text-system-brand text-weight-medium q-list-depth">
                <q-list-header class="no-margin-top text-weight-bold text-empower module-title-size">
                  <q-btn round size="18px" text-color="empower" icon="ion-more" class="float-right" style="margin-top:-10px;margin-right:-10px"/>
                  <q-icon name="ion-people" size="32px" class="on-left-sm"/> Team
                </q-list-header>
                <q-item-separator/>
                <q-item item link tag="label">
                  <q-item-side class="text-center">
                    <q-icon color="educate" name="ion-medal" size="33px" class="on-left-sm"/>
                  </q-item-side>
                  <q-item-main>
                    <q-item-tile label class="capitalize">
                      Haitham Al Beik
                    </q-item-tile>
                    <q-item-tile sublabel>
                      +1 508 847 8747
                      <q-chip dense class="on-right-xs">admin</q-chip>
                    </q-item-tile>
                  </q-item-main>
                </q-item>
                <q-item item link tag="label">
                  <q-item-side class="text-center">
                    <q-icon name="ion-contact" size="33px" class="on-left-sm"/>
                  </q-item-side>
                  <q-item-main>
                    <q-item-tile label class="capitalize">
                      Kelsey Peel
                    </q-item-tile>
                    <q-item-tile sublabel>
                      +1 774 245 5299
                    </q-item-tile>
                  </q-item-main>
                </q-item>
              </q-list>
            </div>
          </q-tab-pane>
        </q-tabs>

      </div>
    </div>

    <!--
      STICKY_BUTON: Business Details
    -->
    <q-page-sticky position="bottom-right" :offset="[20, 20]">
      <transition appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
        <q-btn size="lg" v-ripple round push color="primary" @click="qrcode" class="shadow-24">
          <img src="/statics/_demo/qrcode_white.svg" width="28">
        </q-btn>
      </transition>
    </q-page-sticky>

    <!--
      MODAL: Loyalty Today's Rewards
    -->
    <q-modal v-model="dialogModuleStatsLoyaltyTodayShow" minimized>
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>Rewards Today</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <div v-if="dialogModuleStatsLoyaltyTodayData === null" class="layout-padding">
            <q-progress indeterminate color="secondary" stripe animate height="12px"/>
          </div>
          <div v-else style="margin-top: -40px">
            <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
              <q-list-header class="text-weight-bold text-grey text-left">{{ today_date }}</q-list-header>
              <q-item disabled v-if="dialogModuleStatsLoyaltyTodayData === false || !dialogModuleStatsLoyaltyTodayData.length" class="text-center">
                <q-item-main class="text-center">
                  No rewards at this time.
                </q-item-main>
              </q-item>
              <q-item v-else v-for="i in dialogModuleStatsLoyaltyTodayData" :key="`dll-${i}`">
                <q-item-main>
                  <q-item-tile v-if="i.data" label class="capitalize">{{ i.data.split('_')[1] }}</q-item-tile>
                  <q-item-tile v-else label>Rewards</q-item-tile>
                </q-item-main>
                <q-item-side right>
                  {{ i.total }}
                </q-item-side>
              </q-item>
            </q-list>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Loyalty Enter PIN
    -->
    <q-modal v-model="dialogRequestPinShow" minimized :no-esc-dismiss="dialogRequestPinCheckingKeep" :no-backdrop-dismiss="dialogRequestPinCheckingKeep">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>Loyalty Pin</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogRequestPinChecking" :disabled="dialogRequestPinValue.length < 4" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="loyaltyCheckPIN">
                CHECK
              </q-btn>
            </q-card-main>
          </q-card>
          <q-toggle checked-icon="ion-ios-lock" unchecked-icon="ion-ios-unlock" v-model="dialogRequestPinCheckingKeep" :dark="anyDarkmode"/>
        </q-toolbar>
        <div class="layout-padding">
          <PincodeInput :disabled="dialogRequestPinChecking" :autofocus="true" v-model="dialogRequestPinValue" placeholder="" :dark="anyDarkmode"/>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Loyalty Request View
    -->
    <q-modal id="dialogRequest" v-model="dialogRequestShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout v-if="dialogRequestIndex !== null && requests[dialogRequestIndex]">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>#{{ requests[dialogRequestIndex].info.pin }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogRequestShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="margin-auto-lr text-system-brand layout-padding no-padding-tb row justify-center items-center" style="max-width: 500px">

          <!-- use list -->

          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="font-size-180p margin-auto-lr limit-width-1024 full-width full-height text-system-brand text-weight-semibold uppercase"
                :color="requests[dialogRequestIndex].info.stamps === product.data.business.loyalty.stamps ? 'value' : 'standout'"
                text-color="white" rounded @click="loyaltyStatusUpdate(requests[dialogRequestIndex].info.stamps + 1)">
                {{ requests[dialogRequestIndex].info.stamps === product.data.business.loyalty.stamps ? 'FREE ITEM' : '+1' }}
              </q-btn>
            </q-card-main>
          </q-card>

          <q-card v-if="requests[dialogRequestIndex].info.stamps === product.data.business.loyalty.stamps" class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="font-size-180p margin-auto-lr limit-width-1024 full-width full-height text-system-brand text-weight-semibold uppercase" color="standout" text-color="white" rounded @click="loyaltyStatusCarryover(requests[dialogRequestIndex].info.stamps + 1)">
                CARRYOVER
              </q-btn>
            </q-card-main>
          </q-card>

          <!-- list carryovers -->
          <div v-if="requests[dialogRequestIndex].info.carryover" class="margin-auto-lr text-center">
            <p>{{ requests[dialogRequestIndex].info.carryover }} Free Items</p>
            <template v-for="i in requests[dialogRequestIndex].info.carryover" v-key="`loyalty-co-${i}`">
              <q-btn :key="`loyalty-ctr-co-btn-${i}`"
                class="text-family-brand text-weight-semibold font-size-140p bg-value-gradient"
                bg-color="value-gradient"
                style="margin:10px"
                outline rounded @click="loyaltyStatusUseCarryover(i)" icon="ion-gift">&times;{{ i }}</q-btn>
            </template>
            <q-card-separator style="margin-bottom: 20px; margin-top: 20px"/>
          </div>

          <!-- list stamps -->
          <div class="margin-auto-lr text-center">
            <template v-for="i in product.data.business.loyalty.stamps" v-key="`loyalty-${i}`">
              <q-btn v-if="i > requests[dialogRequestIndex].info.stamps" class="text-family-brand text-weight-semibold font-size-160p" style="margin:10px"
                :key="`loyalty-ctr-${i}`" outline round @click="loyaltyStatusUpdate(i)">{{ i }}</q-btn>
              <q-btn v-else disabled  class="text-family-brand text-weight-semibold font-size-160p" style="margin:10px"
                :key="`loyalty-ctr-dis-${i}`" outline round>
                <img src="/statics/_demo/checkmark.circle.fill.svg"/>
              </q-btn>
            </template>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Manage Spaces
    -->
    <q-modal id="dialogModuleServicesStatusUpdate" v-model="dialogServicesSpacesShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>manage spaces</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogSpacesManage">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="text-center text-shallow text-weight-semibold">Coming Soon</p>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Update Component
    -->
    <q-modal id="dialogModuleServicesStatusUpdate" v-model="dialogServicesStatusUpdateShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>modify service</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogServicesDuplicating" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click="duplicateService()">
                {{ $t('DUPLICATE') }}
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="componentUpdate()">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <!-- <pre>{{ dialogServicesUpdateSelection }}</pre> -->
        <div class="layout-padding no-padding-top" v-if="dialogServicesUpdateSelection">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- availability -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
            <q-list-header class="text-weight-bold text-grey text-left">Availability</q-list-header>
            <q-item>
              <q-item-side class="text-center">
                <img :src="`/statics/services/${dialogServicesUpdateSelection.iconDetails.categoryId}.${dialogServicesUpdateSelection.iconDetails.componentId}.svg`" width="64"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="text-weight-semibold font-size-120p">
                  {{ getServiceName(dialogServicesUpdateSelection) }}
                </q-item-tile>
                <q-item-tile v-if="dialogServicesUpdateSelection.components.length > 1" label class="capitalize text-weight-semibold font-size-120p">
                  <b v-if="dialogServicesUpdateSelection.statusDetails.status === 0" class="text-educate">Fully Available</b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 1" class="text-attention">Partially Available</b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 2" class="text-protect">Not Available</b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 3" class="statusFinder text-purple-l2">Maintenance</b>
                  <b v-else class="text-shallow">Not Set</b>
                </q-item-tile>
                <q-item-tile v-else label class="capitalize text-weight-semibold font-size-120p">
                  <b v-if="dialogServicesUpdateSelection.statusDetails.status === 0" class="text-educate">
                    {{ getComponentDescriptors(dialogServicesUpdateSelection.componentsDetails[dialogServicesUpdateSelection.components[0]]).availability[0].label }}
                  </b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 1" class="text-attention">
                    {{ getComponentDescriptors(dialogServicesUpdateSelection.componentsDetails[dialogServicesUpdateSelection.components[0]]).availability[1].label }}
                  </b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 2" class="text-protect">
                    {{ getComponentDescriptors(dialogServicesUpdateSelection.componentsDetails[dialogServicesUpdateSelection.components[0]]).availability[2].label }}
                  </b>
                  <b v-else-if="dialogServicesUpdateSelection.statusDetails.status === 3" class="statusFinder text-purple-l2">
                    {{ getComponentDescriptors(dialogServicesUpdateSelection.componentsDetails[dialogServicesUpdateSelection.components[0]]).availability[3].label }}
                  </b>
                  <b v-else class="text-shallow">Not Set</b>
                </q-item-tile>
                <q-item-tile sublabel class="text-purple-l2">
                  <q-chip detail square class="margin-xs-l q-chip-computed text-weight-semibold" color="empower" :icon="dialogServicesUpdateSelection.components.length > 1 ? 'ion-apps' : 'ion-cog'" pointing="left" small>
                    {{ dialogServicesUpdateSelection.components.length }}
                    feature{{ dialogServicesUpdateSelection.components.length > 1 ? 's': '' }}
                  </q-chip>
                  <q-chip v-if="dialogServicesUpdateSelection.dependencies.length" detail square class="margin-xs-l q-chip-computed text-weight-semibold" color="purple-l2" icon="ion-git-pull-request" pointing="left" small>
                    {{ dialogServicesUpdateSelection.dependencies.length }}
                    {{ dialogServicesUpdateSelection.dependencies.length === 1 ? 'dependency' : 'dependencies' }}
                  </q-chip>
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold text-subinfo-l">
                  <q-icon name="ion-pulse" size="20px" style="margin-right: -3px;position: relative;top: -1px;"/>
                  {{ serviceLastUpdate(dialogServicesUpdateSelection.uuid) | dtformat }}
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if=     "dialogServicesUpdateSelection.statusDetails.status === 0" name="ion-checkmark-circle" color="educate" size="64px" style="width:64px"/>
                <q-icon v-else-if="dialogServicesUpdateSelection.statusDetails.status === 1" name="ion-alert" color="attention" size="64px" style="width:64px"/>
                <q-icon v-else-if="dialogServicesUpdateSelection.statusDetails.status === 2" name="ion-close-circle" color="protect" size="64px" style="width:64px"/>
                <q-icon v-else-if="dialogServicesUpdateSelection.statusDetails.status === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="64px" style="width:64px"/>
                <q-icon v-else name="ion-code" color="shallow" size="64px" style="width:64px"/>
              </q-item-side>
            </q-item>
            <!-- Effects (dependents) -->
            <template v-if="dialogServicesUpdateSelection.dependentsDetails.direct.length || dialogServicesUpdateSelection.dependentsDetails.indirect.length">
            <q-item-separator/>
            <q-list-header class="text-weight-bold text-grey text-left">Effect</q-list-header>
            <q-item highlight link v-if="dialogServicesUpdateSelection.dependentsDetails.direct.length">
              <q-item-side class="text-center">
                <q-icon name="ion-git-commit" color="purple-l2" size="20px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile sublabel>Impacts {{ dialogServicesUpdateSelection.dependentsDetails.direct.length }} service(s)</q-item-tile>
              </q-item-main>
            </q-item>
            <q-item highlight link v-if="dialogServicesUpdateSelection.dependentsDetails.indirect.length">
              <q-item-side class="text-center">
                <q-icon name="ion-git-network" color="purple-l2" size="20px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile sublabel>
                  Influences {{ dialogServicesUpdateSelection.dependentsDetails.indirect.length }} additional service(s)</q-item-tile>
              </q-item-main>
            </q-item>
            </template>
          </q-list>

          <!-- Features -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Features
              <span v-if="dialogServicesUpdateSelection.components.length">({{ dialogServicesUpdateSelection.components.length }})</span>
            </q-list-header>
            <q-item v-for="comp in dialogServicesUpdateSelection.componentsDetails" :key="`modify-comp-${comp.uuid}`"
            @click.native="changeComponentStatus(comp)">
              <!-- {{ comp }} -->
              <q-item-side class="text-center" @click.native.prevent.stop="removeComponentDialog(dialogServicesUpdateSelection, comp)">
                <q-icon name="ion-remove-circle-outline" color="protect" size="33px"/>
              </q-item-side>
              <q-item-side v-if="dialogServicesUpdateSelection.components.length > 1" class="text-center" @click.native.prevent.stop="serviceChangeIcon(comp.uuid)">
                <q-icon :name="(dialogServicesUpdateSelection.icon === comp.uuid) ? 'ion-checkmark-circle-outline' : 'ion-radio-button-off'" size="33px"></q-icon>
              </q-item-side>
              <q-item-side class="text-center">
                <img :src="`/statics/services/${comp.categoryId}.${comp.componentId}.svg`" width="33"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">{{ getComponentName(comp)}}</q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold text-subinfo-l">
                  <q-icon name="ion-pulse" size="20px" style="margin-right: -3px;position: relative;top: -1px;"/>
                  {{ comp.updated | dtformat }}
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand text-weight-bold text-subinfo-l ellipsis hidden">
                  <q-icon name="ion-at" size="20px" style="margin-right: -3px;position: relative;top: -1px;"/>
                  {{ comp.uuid }}
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="comp.status === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                <q-icon v-else-if="comp.status === 1" name="ion-alert" color="attention" size="32px"/>
                <q-icon v-else-if="comp.status === 2" name="ion-close-circle" color="protect" size="32px"/>
                <q-icon v-else-if="comp.status === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                <q-icon v-else name="ion-code" color="shallow" size="32px"/>
              </q-item-side>
            </q-item>
            <!-- add more features (or convert to set) -->
            <q-item-separator v-if="dialogServicesUpdateSelection.components.length"/>
            <q-item link tag="label" @click.native="dialogComponentsAdd(true)"
              :disabled="dialogServicesUpdateSelection.components.length >= dialogServicesAddComponentsLimit">
              <q-item-side class="text-center">
                <q-icon name="ion-add" :color="anyDarkmode ? '' : 'primary'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="text-primary">Add a Feature</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- dependencies -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Dependencies <span v-if="dialogServicesUpdateSelection.dependenciesDetails.dependencies.length">({{ dialogServicesUpdateSelection.dependenciesDetails.dependencies.length }})</span></q-list-header>
            <!-- list out the dependecies -->
            <q-item v-for="deps in dialogServicesUpdateSelection.dependenciesDetails.dependencies" :key="`modify-deps-${deps}`"
            @click.native="componentUpdate(deps)">
              <q-item-side class="text-center" @click.native.prevent.stop="serviceRemoveDependencyDialog(deps)">
                <q-icon name="ion-remove-circle-outline" color="protect" size="33px"/>
              </q-item-side>
              <q-item-side class="text-center">
                <img :src="`/statics/services/${computeServiceProperties(deps).iconDetails.categoryId}.${computeServiceProperties(deps).iconDetails.componentId}.svg`" width="33"/>
                <q-icon color="purple-l2" name="ion-radio-button-on" size="20px" class="relative-position" style="top: -25px; left: -10px; background-color: #fff; border-radius: 2em; width: 22px; height: 22px; margin-right: -16px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  {{ computeServiceProperties(deps).label }}
                  <span v-if="!computeServiceProperties(deps).label">{{ getServiceName(computeServiceProperties(deps)) }}</span>
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand">
                  {{ getServiceCategoryLabel(computeServiceProperties(deps).iconDetails.categoryId) }}
                  <!-- {{ computeServiceProperties(deps).iconDetails.categoryId }} &gt; {{ computeServiceProperties(deps).iconDetails.componentId }} -->
                </q-item-tile>
                <!-- <q-item-tile sublabel class="text-fixedwidth-brand">{{ computeServiceProperties(deps).uuid }}</q-item-tile> -->
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="serviceStatus(deps) === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 1" name="ion-alert" color="attention" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 2" name="ion-close-circle" color="protect" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                <q-icon v-else name="ion-code" color="shallow" size="32px"/>
              </q-item-side>
            </q-item>
            <!-- add more features (or convert to set) -->
            <q-item-separator v-if="dialogServicesUpdateSelection.dependenciesDetails.dependencies.length"/>
            <q-item link tag="label" @click.native="dialogDependencyAdd()"
              :disabled="dialogServicesUpdateSelection.dependenciesDetails.dependencies.length >= dialogServicesAddComponentsLimit">
              <q-item-side class="text-center">
                <q-icon name="ion-add" :color="anyDarkmode ? '' : 'primary'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="text-primary">Link a Service</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- details -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Settings</q-list-header>
            <q-item>
              <q-item-side class="text-center">
                <q-icon name="ion-qr-scanner" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Icon</q-item-tile>
                <q-item-tile sublabel>
                  <template v-if="dialogServicesUpdateSelection.components.length === 1">Using feature icon</template>
                  <template v-else>Selected <q-icon name="ion-checkmark-circle-outline" class="text-weight-semibold" size="18px"/> from feature list</template>
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <img v-if="dialogServicesUpdateSelection.icon" :key="`services-icon-${dialogServicesUpdateSelection.icon}`" :src="`/statics/services/${dialogServicesUpdateSelection.iconDetails.categoryId}.${dialogServicesUpdateSelection.iconDetails.componentId}.svg`" height="32"/>
                  <q-icon v-else key="services-icon-off" name="ion-qr-scanner" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px" disabled/>
                </transition>
              </q-item-side>
            </q-item>
            <q-item link tag="label" @click.native="serviceChangeLabel">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Label</q-item-tile>
                <q-item-tile sublabel>Visible to Public</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                {{ dialogServicesUpdateSelection.label }}
                <span v-if="!dialogServicesUpdateSelection.label" class="text-attention">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <q-item link tag="label" @click.native="serviceChangeDescription">
              <q-item-side class="text-center">
                <q-icon name="ion-paper" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Description</q-item-tile>
                <q-item-tile sublabel>Visible to Public</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span v-if="!dialogServicesUpdateSelection.description" class="text-attention">Not Set</span>
                <q-icon v-else name="ion-checkmark-circle" color="educate" size="33px"/>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <q-item link tag="label" @click.native="serviceChangeRefId">
              <q-item-side class="text-center">
                <q-icon name="ion-grid" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>ID <q-chip dense denser>Private</q-chip></q-item-tile>
                <q-item-tile sublabel>Internal reference</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                {{ dialogServicesUpdateSelection.refId }}
                <span v-if="!dialogServicesUpdateSelection.refId" class="text-attention">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
          </q-list>

          <!-- display -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Display</q-list-header>
            <!-- <q-item link tag="label" @click.native="dialogGroupsShow(true)">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="dialogServicesUpdateSelection.group" key="group-yes" name="ion-folder-open" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="group-none" name="ion-folder" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Group</q-item-tile>
                <q-item-tile sublabel>To organize services</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span v-if="!dialogServicesUpdateSelection.group" class="text-attention">Not Set</span>
                <span v-else>{{ dialogServicesUpdateSelection.group }}</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item> -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="!dialogServicesUpdateSelection.private" key="loyalty-enabled-on" name="ion-eye" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="loyalty-enabled-off" name="ion-eye-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Hidden</q-item-tile>
                <q-item-tile sublabel>Not visible on public channel</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="serviceChangePrivacy" v-model="dialogServicesUpdateSelection.private" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-pricetag" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>New 
                  <div class="q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r">NEW</div>
                </q-item-tile>
                <q-item-tile sublabel>Add a "new" label</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="serviceChangeIsNew" v-model="dialogServicesUpdateSelection.isNew" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- cost -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
            <q-list-header class="text-weight-bold text-left">Cost</q-list-header>
            <q-item item link tag="label" @click.native="serviceChangeCostValue">
              <q-item-side class="text-center">
                <q-icon name="ion-wallet" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Price</q-item-tile>
                <q-item-tile sublabel>Starting or final unit cost to fulfill</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span v-if="!dialogServicesUpdateSelection.cost && dialogServicesUpdateSelection.cost !== 0" class="text-attention">Not Set</span>
                <span v-else>{{ dialogServicesUpdateSelection.cost | nformat('0,0.00') }} {{ currency.label }}</span>
              </q-item-side>
            </q-item>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-pie" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Add-on</q-item-tile>
                <q-item-tile sublabel>Not a standalone service</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="serviceChangePartial" v-model="dialogServicesUpdateSelection.partial" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- requests -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
            <q-list-header class="text-weight-bold text-left">Requests</q-list-header>
            <q-item item link tag="label" disabled>
              <q-item-side class="text-center">
                <q-icon name="ion-download" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>
                  Requests
                  <q-chip denser dense>Coming soon</q-chip>
                </q-item-tile>
                <q-item-tile sublabel>Customers can request this service</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="serviceChangeRequests" v-model="dialogServicesUpdateSelection.requests" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <q-item item :disabled="!dialogServicesUpdateSelection.requests" link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-stopwatch" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>
                  Fulfillment Time
                  <q-chip denser dense>Coming soon</q-chip>
                </q-item-tile>
                <q-item-tile sublabel>
                  Time (in seconds) to process request
                </q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span class="text-attention">Not Set</span>
              </q-item-side>
            </q-item>
            <q-item item :disabled="!dialogServicesUpdateSelection.requests" link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-flask" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>
                  Workflow
                  <q-chip denser dense>Coming soon</q-chip>
                </q-item-tile>
                <q-item-tile sublabel>
                  Workflow to process request
                </q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span class="text-attention">Not Set</span>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- widgets -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-left">Widgets</q-list-header>
            <q-item item link tag="label" @click.native="copyToClipboard(widgetSingleServiceWebLink(dialogServicesUpdateSelection.uuid), $event)">
              <q-item-side class="text-center">
                <q-icon name="ion-albums" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Status</q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand">
                  {{ widgetSingleServiceWebLink(dialogServicesUpdateSelection.uuid) }}
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/copy.svg" width="22">
              </q-item-side>
            </q-item>
          </q-list>

          <!-- developer -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-left">UUIDs</q-list-header>
            <q-item item link tag="label" @click.native="copyToClipboard(dialogServicesUpdateSelection.uuid, $event)">
              <q-item-side class="text-center">
                <q-icon name="ion-at" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>
                  <q-chip square dense>Service</q-chip>
                  {{ dialogServicesUpdateSelection.label }}
                  <span v-if="!dialogServicesUpdateSelection.label">{{ getServiceName(dialogServicesUpdateSelection) || 'No Label' }}</span>
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand">{{ dialogServicesUpdateSelection.uuid }}</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/copy.svg" width="22">
              </q-item-side>
            </q-item>
            <q-item-separator/>
            <q-item v-for="comp in dialogServicesUpdateSelection.componentsDetails" :key="`dev-${comp.uuid}`"
              item link tag="label" @click.native="copyToClipboard(comp.uuid, $event)">
              <q-item-side class="text-center">
                <q-icon name="ion-at" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>
                  <q-chip square dense>Feature</q-chip>
                  {{ getComponentName(comp) }}
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand">{{ comp.uuid }}</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/copy.svg" width="22">
              </q-item-side>
            </q-item>
          </q-list>

          <!-- Danger -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-protect text-left">Danger Zone</q-list-header>
            <!-- delete -->
            <q-item link tag="label" @click.native="removeService(dialogServicesUpdateSelection.uuid)">
              <q-item-side class="text-center">
                <q-icon name="ion-trash" color="protect" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize text-protect">Delete Service</q-item-tile>
                <q-item-tile sublabel>Operation cannot be reversed</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <q-list-header class="text-weight-bold text-grey text-right">{{ dialogServicesUpdateSelection.uuid }}<br>{{ dialogServicesUpdateSelection.statusDetails.updated | dt12format_EST }} (EST)</q-list-header>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Manage (Create/Remove/Select) Groups
    -->
    <q-modal id="dialogModuleGroupsAdd" v-model="dialogServicesGroupsShow" position="bottom" class="appLayer dialog-item dialog-grow dialog-grow-full">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title v-if="dialogServicesGroupsSelectionMode">select group</q-toolbar-title>
          <q-toolbar-title v-else>manage groups</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="soundPlay('sheet_up'); groupCreate()">
                <q-icon name="ion-add-circle" size="28px"/> &nbsp; Create
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogGroupsHide">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- populate groups -->
          <q-list v-if="!product.data.business.groups.order.length" class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
            <q-item>
              <q-item-main class="text-center text-grey">
                <q-item-tile label>No groups create. Tap <q-icon name="ion-add-circle" size="22px"/> to create a group of services.</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <q-list v-else class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <draggable
              :list="product.data.business.groups.order"
              @end="groupReorderGroups()"
            >
            <q-item link tag="label"
              v-for="(groupId, groupIndex) in product.data.business.groups.order"
              :key="`group-guid-${groupId}`" @click.native="dialogGroupShow(groupId)">
              <q-item-side class="text-center">
                <q-icon name="ion-folder-open" size="33px"></q-icon>
                <q-icon v-if="product.data.business.groups.list[groupId].private" color="protect" name="ion-eye-off" size="20px" class="absolute" style="top: 8px; left: 28px; height: 20px; border: 4px solid #fff; background: #fff; border-radius: 2em"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>{{ product.data.business.groups.list[groupId].title }}</q-item-tile>
                <q-item-tile sublabel>{{ product.data.business.groups.list[groupId].description }}</q-item-tile>
              </q-item-main>
              <q-item-side v-if="product.data.business.groups.list[groupId].services && product.data.business.groups.list[groupId].services.length > 0">
                <q-chip>{{ product.data.business.groups.list[groupId].services && product.data.business.groups.list[groupId].services.length }}</q-chip>
              </q-item-side>
              <q-item-side right v-if="!dialogServicesGroupsSelectionMode">
                <q-icon name="ion-reorder" size="33px" style="cursor: move"></q-icon>
              </q-item-side>
            </q-item>
            </draggable>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Manage Group
    -->
    <q-modal id="dialogModuleGroupSettings" v-model="dialogServicesGroupShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>manage group</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogGroupHide">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top" v-if="dialogServicesGroupId && product.data.business.groups.list[dialogServicesGroupId]">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- Group Id: <q-chip>{{ dialogServicesGroupId }}</q-chip>-->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Settings</q-list-header>
            <!-- Title / Name -->
            <q-item link tag="label" @click.native="groupChangeName">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Name</q-item-tile>
                <q-item-tile sublabel>Group title</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                {{ product.data.business.groups.list[dialogServicesGroupId].title }}
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <!-- Description -->
            <q-item link tag="label" @click.native="groupChangeDescription">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Description</q-item-tile>
                <q-item-tile sublabel>Describe what defines your group</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-weight-semibold text-black">
                <span v-if="!product.data.business.groups.list[dialogServicesGroupId].description" class="text-attention text-weight-semibold">Not Set</span>
                <q-icon v-else name="ion-checkmark-circle" color="educate" size="33px"/>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
          </q-list>
          <!-- Display -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Display</q-list-header>
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="!product.data.business.groups.list[dialogServicesGroupId].private" key="loyalty-enabled-on" name="ion-eye" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="loyalty-enabled-off" name="ion-eye-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Hidden</q-item-tile>
                <q-item-tile sublabel>Not visible on public channel</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="groupChangePrivacy" v-model="product.data.business.groups.list[dialogServicesGroupId].private" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
          <!-- Services -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Services</q-list-header>
            <draggable
              :list="product.data.business.groups.list[dialogServicesGroupId].services"
              @end="groupReorderGroups()"
            >
            <q-item v-for="deps in product.data.business.groups.list[dialogServicesGroupId].services" :key="`modify-deps-${deps}`" link>
              <q-item-side class="text-center" @click.native.prevent.stop="groupRemoveService(deps)">
                <q-icon name="ion-remove-circle-outline" color="protect" size="33px"/>
              </q-item-side>
              <q-item-side class="text-center">
                <img :src="`/statics/services/${computeServiceProperties(deps).iconDetails.categoryId}.${computeServiceProperties(deps).iconDetails.componentId}.svg`" width="33"/>
                <q-icon color="purple-l2" name="ion-folder-open" size="20px" class="relative-position" style="top: -25px; left: -10px; background-color: #fff; border-radius: 2em; width: 22px; height: 22px; margin-right: -16px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  {{ computeServiceProperties(deps).label }}
                  <span v-if="!computeServiceProperties(deps).label">{{ getServiceName(computeServiceProperties(deps)) }}</span>
                </q-item-tile>
                <q-item-tile sublabel class="text-fixedwidth-brand">
                  {{ getServiceCategoryLabel(computeServiceProperties(deps).iconDetails.categoryId) }}
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="serviceStatus(deps) === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 1" name="ion-alert" color="attention" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 2" name="ion-close-circle" color="protect" size="32px"/>
                <q-icon v-else-if="serviceStatus(deps) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                <q-icon v-else name="ion-code" color="shallow" size="32px"/>
              </q-item-side>
              <q-item-side right>
                <q-icon name="ion-reorder" size="33px" style="cursor: move"></q-icon>
              </q-item-side>
            </q-item>
            </draggable>
            <!-- add more services -->
            <q-item-separator v-if="product.data.business.groups.list[dialogServicesGroupId].services && product.data.business.groups.list[dialogServicesGroupId].services.length"/>
            <q-item link tag="label" @click.native="groupAddService()">
              <q-item-side class="text-center">
                <q-icon name="ion-add" :color="anyDarkmode ? '' : 'primary'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="text-primary">Add a Service</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <!-- Danger -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-protect text-left">Danger Zone</q-list-header>
            <!-- delete -->
            <q-item link tag="label" @click.native="groupRemoveDialog(dialogServicesGroupId)">
              <q-item-side class="text-center">
                <q-icon name="ion-trash" color="protect" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize text-protect">Delete Group</q-item-tile>
                <q-item-tile sublabel>Operation cannot be reversed</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Create a Service
    -->
    <q-modal id="dialogModuleServicesAdd" v-model="dialogServicesAddShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>create service stack</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogServicesAddCreating" :disabled="!(dialogServicesAddComponents.length && dialogServicesAddLabel.length)" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="soundPlay('sheet_up'); serviceCreate()">
                <q-icon name="ion-add-circle" size="28px"/> &nbsp; Create
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogServicesAdd">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- Components -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link :disable="dialogServicesAddCreating">
            <q-list-header class="text-weight-bold text-grey text-left">Features ({{ dialogServicesAddComponents.length }} / {{ dialogServicesAddComponentsLimit }})</q-list-header>
            <transition-group appear enter-active-class="animated400 fadeIn" leave-active-class="animated400 fadeOut">
            <q-item link tag="label"
              v-for="(component, componentIndex) in dialogServicesAddComponents"
              :key="`component-guid-${component.componentListId}`">
              <q-item-side class="text-center" @click.native="dialogServicesAddComponentsIconIndex = componentIndex">
                <q-icon :name="(dialogServicesAddComponentsIconIndex === componentIndex) ? 'ion-checkmark-circle-outline' : 'ion-radio-button-off'" size="33px"></q-icon>
              </q-item-side>
              <q-item-side class="text-center">
                <img :src="`/statics/services/${component.categoryId}.${component.componentId}.svg`" height="32"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>{{ Wings.services.list[component.categoryId][component.componentId].name || Wings.services.list[component.categoryId][component.componentId].id }}</q-item-tile>
                <q-item-tile v-if="Wings.services.list[component.categoryId][component.componentId].description" sublabel>{{ Wings.services.list[component.categoryId][component.componentId].description }}</q-item-tile>
                <!-- <q-item-tile sublabel>{{ component.componentListId }}</q-item-tile> -->
              </q-item-main>
              <q-item-side right class="text-center" @click.native="componentRemove(component.componentListId)">
                <q-icon color="protect" name="ion-trash" size="33px"></q-icon>
              </q-item-side>
            </q-item>
            </transition-group>
            <!-- component add -->
            <q-item-separator v-if="dialogServicesAddComponents.length"/>
            <q-item link tag="label" @click.native="dialogComponentsAdd(false)"
              :disabled="dialogServicesAddComponents.length >= dialogServicesAddComponentsLimit || dialogServicesAddCreating"
              :disable="dialogServicesAddCreating">
              <q-item-side class="text-center">
                <q-icon name="ion-add" :color="anyDarkmode ? '' : 'primary'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="text-primary">Add a Feature</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <!-- Details -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border :disabled="dialogServicesAddCreating">
            <q-list-header class="text-weight-bold text-grey text-left">Details</q-list-header>
            <q-item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Label</q-item-tile>
                <q-item-tile sublabel>Visible to all</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-input
                  :disable="dialogServicesAddCreating"
                  v-model.trim="dialogServicesAddLabel"
                  class="text-weight-semibold text-black" style="width:180px"
                  autofocus
                  placeholder="eg. Barista"/>
              </q-item-side>
            </q-item>
            <q-item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-grid" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>ID</q-item-tile>
                <q-item-tile sublabel>Visible to team</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-input
                  :disable="dialogServicesAddCreating"
                  v-model.trim="dialogServicesAddId"
                  class="text-weight-semibold text-black" style="width:180px"
                  upper-case
                  placeholder="eg. B02A, #23"/>
              </q-item-side>
            </q-item>
            <q-item>
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <img v-if="dialogServicesAddComponents.length" :key="`services-icon-${dialogServicesAddComponents[dialogServicesAddComponentsIconIndex].componentListId}`" :src="`/statics/services/${dialogServicesAddComponents[dialogServicesAddComponentsIconIndex].categoryId}.${dialogServicesAddComponents[dialogServicesAddComponentsIconIndex].componentId}.svg`" height="32"/>
                  <q-icon v-else key="services-icon-off" name="ion-qr-scanner" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px" disabled/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Icon</q-item-tile>
                <q-item-tile sublabel>Select from feature list</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="dialogServicesAddComponents.length" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-weight-semibold text-attention">Not Set</span>
              </q-item-side>
            </q-item>
            <!--
            <q-item link tag="label" disabled>
              <q-item-side class="text-center">
                <q-icon name="ion-eye-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Private <q-chip class="q-chip-pro q-chip-denser" dense>PRO</q-chip></q-item-tile>
                <q-item-tile sublabel>Visible to team members only</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle :dark="anyDarkmode" disable/>
              </q-item-side>
            </q-item>
            -->
          </q-list>
          <!-- support -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Support</q-list-header>
            <q-item link tag="label">
              <q-item-main>
                <q-collapsible icon="ion-apps" label="What's a stack?">
                  <div class="text-weight-medium text-emphasis">
                    Create a stack to group related features into a combined service, where the overall availability is determined by the status of all included features. Ideal for interconnected services where each feature's status impacts the entire offering, unlike stand-alone, independent feature-based services.
                  </div>
                </q-collapsible>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!-- MODAL: Select a service -->
    <q-modal id="dialogServicesSelector" v-model="dialogServicesSelectorShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>select a service</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogServicesSelectorShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <template v-if="dialogServicesSelectorList.length">
            <p v-if="dialogServicesUpdateSelection"><span class="text-weight-semibold">{{ getServiceName(dialogServicesUpdateSelection) }}</span> can depend on the following services:</p>
            <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
              <q-item v-for="service in dialogServicesSelectorList" :key="`service-selector-${service}`" @click.native="dialogDependencySelect(service)">
                <q-item-side class="text-center">
                  <img :src="`/statics/services/${computeServiceProperties(service).iconDetails.categoryId}.${computeServiceProperties(service).iconDetails.componentId}.svg`" width="33"/>
                </q-item-side>
                <q-item-main>
                  <q-item-tile label class="capitalize">
                    {{ computeServiceProperties(service).label }}
                    <span v-if="!computeServiceProperties(service).label">{{ getServiceName(computeServiceProperties(service)) }}</span>
                  </q-item-tile>
                  <q-item-tile sublabel class="text-fixedwidth-brand">
                    {{ getServiceCategoryLabel(computeServiceProperties(service).iconDetails.categoryId) }}
                    <!-- {{ computeServiceProperties(service).iconDetails.categoryId }} &gt; {{ computeServiceProperties(service).iconDetails.componentId }} -->
                  </q-item-tile>
                </q-item-main>
                <q-item-side right>
                  <q-icon v-if="serviceStatus(service) === 0" name="ion-checkmark-circle" color="educate" size="32px"/>
                  <q-icon v-else-if="serviceStatus(service) === 1" name="ion-alert" color="attention" size="32px"/>
                  <q-icon v-else-if="serviceStatus(service) === 2" name="ion-close-circle" color="protect" size="32px"/>
                  <q-icon v-else-if="serviceStatus(service) === 3" name="ion-code-working" class="statusFader" color="purple-l2" size="32px"/>
                  <q-icon v-else name="ion-code" color="shallow" size="32px"/>
                </q-item-side>
              </q-item>
            </q-list>
          </template>
          <template v-else>
            <p class="text-center text-shallow text-weight-semibold">No available services</p>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Add a component
    -->
    <q-modal id="dialogModuleComponentsAdd" v-model="dialogComponentsAddShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>select feature</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogComponentsAdd">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link
            v-for="cat in dialogServicesAddCategories" :key="cat.value">
            <q-list-header class="text-weight-bold text-grey text-left">{{ cat.label }}</q-list-header>
            <q-item link tag="label"
              v-for="comp in Wings.services.list[cat.value]" :key="comp.id"
              @click.native="dialogComponentsAdd(); componentAdd(comp.id, cat.value)">
              <q-item-side class="text-center">
                <img :src="`/statics/services/${cat.value}.${comp.id}.svg`" height="32"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>{{ comp.name || comp.id }}</q-item-tile>
                <q-item-tile v-if="comp.description" sublabel>{{ comp.description }}</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Module: Today Settings
    -->
    <q-modal id="dialogModuleSettingsToday" v-model="dialogModuleSettingsTodayShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>today</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="moduleSettingsToday">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Operations</q-list-header>
            <q-item item link tag="label" @click.native="updateStatusChange()">
              <q-item-side class="text-center">
                <q-icon name="ion-warning" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Announce Change</q-item-tile>
                <q-item-tile sublabel>Notify change of operations</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <span v-if="channel_orders === 0" class="text-weight-semibold text-educate">No Change</span>
                <span v-else class="text-weight-semibold text-protect">
                  {{ this.getStatusChangeData(this.channel_orders).label }}
                </span>
              </q-item-side>
              <q-item-side right>
                <q-icon v-if="this.channel_orders === 0" name="ion-checkmark-circle" color="educate" size="33px"/>
                <img v-else :src="this.getStatusChangeData(this.channel_orders).icon" width="33"/>
              </q-item-side>
            </q-item>
            <q-item item link @click.native="triggerDialogHOO()">
              <q-item-side class="text-center">
                <q-icon name="ion-time" size="33px" :color="anyDarkmode ? '' : 'blue-grey-10'"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Hours</q-item-tile>
                <q-item-tile sublabel>
                  {{ today_current_time }}
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-item-tile sublabel class="capitalize">{{ today_day }}</q-item-tile>
                <span class="block text-weight-semibold text-black">
                  <template v-if="today_day">
                    <template v-if="product.data.business.hoo[today_day].is24">24 Hours</template>
                    <template v-else-if="product.data.business.hoo[today_day].isClosed">Closed</template>
                    <template v-else>
                      {{ product.data.business.hoo[today_day].open | t12format }}-{{ product.data.business.hoo[today_day].close | t12format }}
                    </template>
                  </template>
                </span>
              </q-item-side>
            </q-item>
          </q-list>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Channel</q-list-header>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="channel_online" key="channel-online-on" name="ion-radio" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px" class="pulse"/>
                  <q-icon v-else key="channel-online-off" name="ion-radio-button-on" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  <template v-if="channel_online">Live</template>
                  <template v-else>Offline</template>
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="channel_online" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Module: Loyalty Settings
    -->
    <q-modal id="dialogModuleSettingsLoyalty" v-model="dialogModuleSettingsLoyaltyShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>loyalty</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="moduleSettingsLoyalty">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-alert v-if="product.master_uri" type="wing" icon="ion-warning">
            Loyalty settings is managed by another channel. Contact administrator for details.
          </q-alert>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Statistics</q-list-header>
            <q-item item link tag="label" @click.native="moduleStatsLoyaltyToday()">
              <q-item-side class="text-center">
                <q-icon name="ion-clock" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Today</q-item-tile>
                <q-item-tile sublabel>View today's rewards</q-item-tile>
              </q-item-main>
            </q-item>
            <!--
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-calendar" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Month</q-item-tile>
                <q-item-tile sublabel>View this month's activities</q-item-tile>
              </q-item-main>
            </q-item>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-stats" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Report</q-item-tile>
                <q-item-tile sublabel>Generate a custom report</q-item-tile>
              </q-item-main>
            </q-item>
            -->
          </q-list>

          <q-list :disabled="product.master_uri" class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Appearance</q-list-header>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="loyalty_enabled" key="loyalty-enabled-on" name="ion-eye" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="loyalty-enabled-off" name="ion-eye-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Offer Loyalty</q-item-tile>
                <q-item-tile sublabel>Digital loyalty offering to subscribers</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="loyalty_enabled" :dark="anyDarkmode" :disable="product.master_uri"/>
              </q-item-side>
            </q-item>
            <q-item item link tag="label" @click.native="moduleSettingsLoyaltyStamps()">
              <q-item-side class="text-center">
                <q-icon name="ion-done-all" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Stamps</q-item-tile>
                <q-item-tile sublabel>Transactions required to recieve reward</q-item-tile>
              </q-item-main>
              <q-item-side right class="text-black">
                {{ this.product.data.business.loyalty.stamps }}
              </q-item-side>
            </q-item>
            <q-item item link tag="label" disabled>
              <q-item-side class="text-center">
                <q-icon name="ion-gift" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Reward</q-item-tile>
                <q-item-tile sublabel>Reward type given</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <span v-if="!this.product.data.business.loyalty.rewardsMetaOptions.length">
                  Free Item
                </span>
                <span v-else>
                  Multiple Types
                </span>
              </q-item-side>
            </q-item>
            <q-item item link tag="label" disabled>
              <q-item-side class="text-center">
                <q-icon name="ion-image" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Image</q-item-tile>
                <q-item-tile sublabel>Loyalty Card Photo</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon name="ion-checkmark-circle" color="educate" size="33px"/>
                <!-- <q-item-tile>
                  <img style="height:33px" src="https://res.cloudinary.com/letsbutterfly/image/upload/v1689622243/wings-app/features/7a5d67ccd654ff987b01d1cf8e5dcd45.loyalty_image.jpg"/>
                </q-item-tile> -->
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Channel Settings
    -->
    <q-modal id="dialogSettings" v-model="dialogSettingsShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>channel</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click="openURL(productFullURI)">
                {{ $t('PREVIEW') }}
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogSettingsShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- <p class="text-center">Define your <strong class="text-empower">business</strong> settings and brand.</p> -->

          <!-- Channel Details -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Channel</q-list-header>
            <!-- link -->
            <q-item link tag="label" @click.native="openURL(productFullURI)">
              <q-item-side class="text-center">
                <q-icon name="ion-radio" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Channel Page</q-item-tile>
                <q-item-tile sublabel>Open channel web link</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon name="ion-open" size="33px"/>
              </q-item-side>
            </q-item>
            <!-- qr code -->
            <q-item link tag="label" @click.native="qrcode">
              <q-item-side class="text-center">
                <q-icon name="ion-qr-scanner" :color="anyDarkmode ? 'white' : 'blue-grey-10'" size="33px"/>
                <!-- <img src="/statics/_demo/qrcode_primary.svg" width="26" :style="anyDarkmode ? 'filter: brightness(0.2)' : 'filter: grayscale(1)'"> -->
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Channel QR Code</q-item-tile>
                <q-item-tile sublabel>View / Download QR Code</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="product.data.qrcode_ref" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-weight-semibold text-attention">Not Set</span>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- Media -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Media</q-list-header>
            <!-- logo -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-flower" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Logo</q-item-tile>
                <q-item-tile sublabel>Brand icon</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <!-- <q-icon class="margin-xs-l" name="ion-checkmark-circle" color="educate" size="33px"/> -->
                <q-icon v-if="product.data.media.logo && product.data.media.logo.url" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-weight-semibold text-attention">Not Set</span>
              </q-item-side>
            </q-item>
            <!-- gallery -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-images" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Gallery</q-item-tile>
                <q-item-tile sublabel>Cover gallery photos</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <template v-if="product.data.media.photos && product.data.media.photos.resources">
                  <q-chip>{{ product.data.media.photos.resources.length }}</q-chip>
                  <q-icon class="margin-xs-l" name="ion-checkmark-circle" color="educate" size="33px"/>
                </template>
                <span v-else class="text-attention text-weight-semibold">Not Set</span>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- About: Description / Phone / Website -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">About</q-list-header>
            <!-- Title / Name -->
            <q-item link tag="label" @click.native="channelChangeName">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Name</q-item-tile>
                <q-item-tile sublabel>Channel title</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="product.data.business.name" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-attention text-weight-semibold">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <!-- Description -->
            <q-item link tag="label" @click.native="channelChangeDescription">
              <q-item-side class="text-center">
                <q-icon name="ion-information-circle-outline" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Description</q-item-tile>
                <q-item-tile sublabel>About your services</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="product.data.business.metas.description" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-attention text-weight-semibold">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <!-- Website -->
            <q-item link tag="label" @click.native="channelChangeWebsite">
              <q-item-side class="text-center">
                <q-icon name="ion-link" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Website</q-item-tile>
                <q-item-tile sublabel>Marketing site</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="product.data.business.website" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-attention text-weight-semibold">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
            <!-- Phone -->
            <q-item link tag="label" @click.native="channelChangePhone">
              <q-item-side class="text-center">
                <q-icon name="ion-call" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Phone</q-item-tile>
                <q-item-tile sublabel>Main contact</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-icon v-if="product.data.business.phone" name="ion-checkmark-circle" color="educate" size="33px"/>
                <span v-else class="text-attention text-weight-semibold">Not Set</span>
                <img helper class="on-right-sm relative-position" style="top:4px" src="/statics/_demo/edit-text.svg" width="22">
              </q-item-side>
            </q-item>
          </q-list>

          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-left">Localization</q-list-header>
            <!-- curreny -->
            <q-item link tag="label" @click.native="changeCurrency">
              <q-item-side class="text-center">
                <q-icon name="ion-cash" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Currency</q-item-tile>
                <q-item-tile sublabel>Unit for pricing</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-item-tile label>{{ currency.label }}</q-item-tile>
                <q-item-tile sublabel class="capitalize">{{ currency.name }}</q-item-tile>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- Danger -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-protect text-left">Danger Zone</q-list-header>
            <!-- reset services -->
            <q-item link tag="label" @click.native="removeAllServices">
              <q-item-side class="text-center">
                <q-icon name="ion-trash" color="protect" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize text-protect">Delete All Services</q-item-tile>
                <q-item-tile sublabel>Operation cannot be reversed</q-item-tile>
              </q-item-main>
            </q-item>
            <!-- delete -->
            <q-item link tag="label" @click.native="remove">
              <q-item-side class="text-center">
                <q-icon name="ion-trash" color="protect" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize text-protect">Delete Channel</q-item-tile>
                <q-item-tile sublabel>Operation cannot be reversed</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <q-list-header class="text-weight-bold text-grey text-right">#{{ product.id }}-{{ product.mutations }} {{ product.updated | dt12format_EST }} (EST)</q-list-header>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Hours of Operations
    -->
    <q-modal id="dialogHOO" v-model="dialogHOOShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>Hours of Operations</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogHOOShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- display days of the week -->
          <p class="text-center">Define your <strong class="text-empower">regular</strong> hours of operation.</p>
          <q-tabs color="gray" inverted :dark="anyDarkmode">
            <q-tab
              v-for="(day, ix) of daysOfTheWeek"
              :key="`tab-hoo-${day}-key`"
              :name="`tab-hoo-${day}`"
              :default="today_day === day"
              slot="title"
              :data-tabix="`tab-${ix}`"
              @select="HooSelectionChange"
            >
              <span class="font-size-120p text-system-brand text-weight-semibold" :class="{ 'text-underline': today_day === day }">{{ day.substr(0,2) }}</span>
            </q-tab>
            <q-tab-pane
              :key="`tab-hoo-${day}-pane-key`"
              :name="`tab-hoo-${day}`" v-for="(day, ix) of daysOfTheWeek">

              <p class="text-center on-top-lg text-weight-semibold bg-shallower"
                style="
                  border: 1px solid #eee;
                  margin: 10px;
                  padding: 10px;
                  border-radius: 0.4em;
                  width: auto;"
                >
                <!-- <q-chip style="margin-top: -10px; margin-bottom: -6px" v-if="today_day === day" dense class="q-chip-subinfo">today</q-chip> -->
                {{ day }}:
                <template v-if="dialogHOOIndex">
                  <span v-if="product.data.business.hoo[dialogHOOIndex].isClosed">Closed</span>
                  <span v-else-if="product.data.business.hoo[dialogHOOIndex].is24">24 Hours</span>
                  <span v-else>{{ product.data.business.hoo[dialogHOOIndex].open | t12format }}-{{ product.data.business.hoo[dialogHOOIndex].close | t12format }}</span>
                </template>
              </p>

            </q-tab-pane>
          </q-tabs>

          <q-list link no-border>
            <q-list-header class="no-margin-top text-weight-bold text-grey">Duration
              <template v-if="dialogHOOIndex">
              <span v-if="product.data.business.hoo[dialogHOOIndex].isClosed">(None)</span>
              <span v-else-if="product.data.business.hoo[dialogHOOIndex].is24">(24 hours)</span>
              <span v-else>({{ (HooSelectionDuration(product.data.business.hoo[dialogHOOIndex].open, product.data.business.hoo[dialogHOOIndex].close, 'minutes') / 60).toString().replace('.5', '½') }} hours)</span>
            </template>
            </q-list-header>
            <q-item tag="label" :disabled="dialogHOO24h || dialogHOOClosed">
              <q-item-side class="text-center">
                <q-icon name="ion-sunny" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  Opening
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-select radio @input="HooSelectionChangeOpen" separator v-model="dialogHOOStart" :options="HOOOptionsOpen" :disabled="dialogHOO24h || dialogHOOClosed" :readonly="dialogHOO24h || dialogHOOClosed"/>
              </q-item-side>
            </q-item>
            <q-item tag="label" :disabled="dialogHOO24h || dialogHOOClosed">
              <q-item-side class="text-center">
                <q-icon name="ion-moon" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  Closing
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-select radio @input="HooSelectionChangeClose" separator v-model="dialogHOOEnd" :options="HOOOptionsClose" :disabled="dialogHOO24h || dialogHOOClosed" :readonly="dialogHOO24h || dialogHOOClosed"/>
              </q-item-side>
            </q-item>
            <q-item-separator/>
            <!-- <q-list-header class="no-margin-top text-weight-bold text-grey">Day</q-list-header> -->
            <q-item tag="label" :disabled="dialogHOOClosed">
              <q-item-side class="text-center">
                <q-icon name="ion-infinite" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  24 Hours
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="HooSelectionChange24h" v-model="dialogHOO24h" :dark="anyDarkmode" :readonly="dialogHOOClosed" :disabled="dialogHOOClosed"/>
              </q-item-side>
            </q-item>
            <q-item tag="label" :disabled="dialogHOO24h">
              <q-item-side class="text-center">
                <q-icon name="ion-exit" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  Closed
                </q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle @input="HooSelectionChangeClosed" v-model="dialogHOOClosed" :dark="anyDarkmode" :readonly="dialogHOO24h" :disabled="dialogHOO24h"/>
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: QR Code
    -->
    <q-modal id="dialogQRCode" v-model="dialogQRCodeShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/qrcode_primary.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('QRCODE.LABEL') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogQRCodeDownloading" :disabled="!channel.qrcode_ref" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="qrcodeDownload">
                {{ $t('DOWNLOAD') }}
                <q-spinner-puff slot="loading" size="20px" />
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogQRCodeShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <img v-if="channel.qrcode_ref" :src="channel.qrcode_ref" style="width:95%;border-radius:2rem;max-width:53vh">
          <div v-else class="layout-padding" style="margin-top: 40%; padding: 0 30%">
            <q-progress indeterminate color="secondary" stripe animate height="12px"/>
          </div>
          </transition>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <p v-if="channel.qrcode_ref" class="text-family-brand text-weight-semibold text-center font-size-100p text-attention" style="word-break: break-all;margin-bottom:-20px">
            {{ product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs') }}
            <q-btn flat round icon="ion-link" :title="product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs')" @click="openURL(product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs'))"/>
            <q-btn flat round icon="ion-refresh" @click="qrcodeCreate"/>
          </p>
          </transition>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Removing Product
    -->
    <q-modal v-model="dialogRemovingShow" minimized no-esc-dismiss no-backdrop-dismiss>
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t(getEcosystemLabel('DELETE.DELETING')) }}</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <q-progress indeterminate color="secondary" stripe animate height="12px"/>
        </div>
      </q-modal-layout>
    </q-modal>
  </div>
  <div v-else class="flex flex-center" style="height: 100vh">
    <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block;"/>
  </div>
</template>

<script>
import { axiosLIO } from 'plugins/axios'
import { openURL, animate, easing, debounce } from 'quasar'
import moment from 'moment'
import nformat from 'vue-filter-number-format'
import PubNub from 'pubnub'

import Wings from '../services/wings-2.js'

import PincodeInput from 'vue-pincode-input'

import _ from 'lodash'

import draggable from 'vuedraggable'

export default {
  name: 'PageProductsEdit',
  props: [
    'auth',
    'authenticated',
    'lang',
    'ecosystem',
    'anyDarkmode',
    'wingletDialogTrigger',
    'accountDialogTrigger',
    'drawerTriggerShow',
    'soundPlay',
    'modalAdapt',
    'isProduction'
  ],
  components: {
    PincodeInput,
    draggable
  },
  data () {
    return {
      beginFlag: false,
      pn: null,
      channel: {
        shortlink: null,
        qrcode_blob: null,
        qrcode_ref: null,
        presense_count: 0
      },
      clients: [],
      channelDisconnectionRequested: false,
      channelFetchTimer: null,
      channelFetchActiveClientsRequestsFirstTime: true,
      channelFetchActiveClientsRequestsLastCount: 0,
      channelFetchActiveClientsRequestsCount: 0,
      channelFetchActiveClientsRequestActive: false,
      Wings: Wings,
      ready: false,
      dialogSettingsShow: false,
      dialogSettingsShowButton: false,
      dialogPerspectiveItem: null,
      dialogPerspectiveShow: false,
      dialogRemovingShow: false,
      dialogQRCodeShow: false,
      dialogQRCodeDownloading: false,
      dialogShareShow: false,
      dialogServicesGroupsShow: false,
      dialogServicesGroupsSelectionMode: false,
      dialogServicesGroupShow: false,
      dialogServicesGroupId: null,
      dialogServicesAddShow: false,
      dialogComponentsAddShow: false,
      dialogComponentStore: false,
      dialogServicesAddLabel: '',
      dialogServicesAddId: '',
      dialogServicesAddCategory: null,
      dialogServicesAddCategoryComponent: null,
      dialogServicesAddComponents: [],
      dialogServicesAddComponentsLimit: 5,
      dialogServicesAddComponentsIconIndex: 0,
      dialogServicesCreateComponent: false,
      dialogServicesAddCreating: false,
      dialogServicesStatusUpdateShow: false,
      dialogServicesDuplicating: false,
      dialogServicesUpdateSelection: null,
      dialogServicesSelectorShow: false,
      dialogServicesSelectorList: [],
      dialogServicesSelectorSelection: null,
      dialogServicesSelectorListCallback: null,
      dialogServicesSpacesShow: false,
      dialogModuleSettingsTodayShow: false,
      dialogModuleSettingsLoyaltyShow: false,
      dialogModuleStatsLoyaltyTodayShow: false,
      dialogModuleStatsLoyaltyTodayData: null,
      dialogRequestShow: false,
      dialogRequestPinShow: false,
      dialogRequestPinValue: '',
      dialogRequestPinValueTimer: null,
      dialogRequestPinChecking: false,
      dialogRequestPinCheckingKeep: false,
      dialogRequestIndex: null,
      dialogHOOShow: false,
      dialogHOOIndex: this.today_day,
      dialogHOOStart: '8:00',
      dialogHOOEnd: '17:00',
      dialogHOO24h: false,
      dialogHOOClosed: false,
      HOOOptions: [
        { label: '12:00 AM', value: '0:00' },
        { label: '12:30 AM', value: '0:30' },
        { label: ' 1:00 AM', value: '1:00' },
        { label: ' 1:30 AM', value: '1:30' },
        { label: ' 2:00 AM', value: '2:00' },
        { label: ' 2:30 AM', value: '2:30' },
        { label: ' 3:00 AM', value: '3:00' },
        { label: ' 3:30 AM', value: '3:30' },
        { label: ' 4:00 AM', value: '4:00' },
        { label: ' 4:30 AM', value: '4:30' },
        { label: ' 5:00 AM', value: '5:00' },
        { label: ' 5:30 AM', value: '5:30' },
        { label: ' 6:00 AM', value: '6:00' },
        { label: ' 6:30 AM', value: '6:30' },
        { label: ' 7:00 AM', value: '7:00' },
        { label: ' 7:30 AM', value: '7:30' },
        { label: ' 8:00 AM', value: '8:00' },
        { label: ' 8:30 AM', value: '8:30' },
        { label: ' 9:00 AM', value: '9:00' },
        { label: ' 9:30 AM', value: '9:30' },
        { label: '10:00 AM', value: '10:00' },
        { label: '10:30 AM', value: '10:30' },
        { label: '11:00 AM', value: '11:00' },
        { label: '11:30 AM', value: '11:30' },
        { label: '12:00 PM', value: '12:00' },
        { label: '12:30 PM', value: '12:30' },
        { label: ' 1:00 PM', value: '13:00' },
        { label: ' 1:30 PM', value: '13:30' },
        { label: ' 2:00 PM', value: '14:00' },
        { label: ' 2:30 PM', value: '14:30' },
        { label: ' 3:00 PM', value: '15:00' },
        { label: ' 3:30 PM', value: '15:30' },
        { label: ' 4:00 PM', value: '16:00' },
        { label: ' 4:30 PM', value: '16:30' },
        { label: ' 5:00 PM', value: '17:00' },
        { label: ' 5:30 PM', value: '17:30' },
        { label: ' 6:00 PM', value: '18:00' },
        { label: ' 6:30 PM', value: '18:30' },
        { label: ' 7:00 PM', value: '19:00' },
        { label: ' 7:30 PM', value: '19:30' },
        { label: ' 8:00 PM', value: '20:00' },
        { label: ' 8:30 PM', value: '20:30' },
        { label: ' 9:00 PM', value: '21:00' },
        { label: ' 9:30 PM', value: '21:30' },
        { label: '10:00 PM', value: '22:00' },
        { label: '10:30 PM', value: '22:30' },
        { label: '11:00 PM', value: '23:00' },
        { label: '11:30 PM', value: '23:30' },
        { label: '12:00 AM', value: '24:00' }
      ],
      daysOfTheWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      datagroups: Wings.datagroups(this),
      datafieldsInitialized: false,
      product: {},
      clock: 0,
      rewardsMetaModel: ['reward_tall'],
      loyaltyDefaultSettings: {
        stamps: 8, // 8 transactions required
        rewardsMetaOptions: [], // free item
        image: false // use default images
      },
      logTimer: null,
      logQueue: []
    }
  },
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    },
    t12format: function (val) {
      return moment(val, 'HH:mm').format('h:mm A')
    },
    dtformat: function (val) {
      return moment(val).format('MM/D/YYYY h:mm A')
    },
    dt12format_EST: function (val) {
      let utcOffset = -(300 + 60 + 60 + 60) / 60
      let currentDateTime = moment(val).utcOffset(utcOffset)
      if (currentDateTime.isDST()) {
        utcOffset += 60
        currentDateTime = moment(val).utcOffset(utcOffset)
      }
      return moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm A')
    }
  },
  beforeCreate () {
    console.log('## beforeCreate')
    // query product information
    axiosLIO.get('/product/' + this.$route.params.uri).then((res) => {
      console.log(res)
      let product = res.data.data.product
      product.data = JSON.parse(product.payload)
      // init business, services and components (if not available)
      if (!product.data.qrcode_ref) product.data.qrcode_ref = null
      if (!product.data.business) product.data.business = {}
      if (!product.data.business.currency) product.data.business.currency = Wings.cost.default.currencyIndex
      if (!product.data.business.services) product.data.business.services = {}
      if (!product.data.business.components) product.data.business.components = {}
      if (!product.data.business.groups) {
        product.data.business.groups = {
          list: {},
          order: []
        }
      }
      this.product = product
      // init localization (if any)
      this.productLocalizationInit()
      // init hours (if any)
      this.productHooInit()
      // init loyalty (if any)
      this.productLoyaltyInit()
      // debug business
      console.log(this.product.data.business)
      // ready
      this.ready = true
      setTimeout(() => {
        document.querySelector('#productsEditContent').classList.remove('no-pointer-events')
        document.querySelector('#appHeader').classList.add('animate-hide')
      }, 400)
    }).catch((e) => {
      this.$router.push(`/${this.$store.state.app.io.ecosystem_id}`)
    })
  },
  mounted () {
    // start rendering
    let me = setInterval(() => {
      if (this.ready) {
        this.begin()
        clearInterval(me)
      }
    }, 500)
    // detect no presence
    // window.addEventListener('beforeunload', this.channelDisconnect)
    // detect change routing
    this.$router.beforeEach((to, from, next) => {
      if (from.matched.some(record => record.path.startsWith('/business/'))) {
        this.channelDisconnect()
      }
      next()
    })
    // listen to events
    this.$eventBus.$on('bchannel-exit', this.exitRequest)
    this.$eventBus.$on('bchannel-today', this.moduleSettingsToday)
    this.$eventBus.$on('bchannel-loyalty', this.moduleSettingsLoyalty)
    this.$eventBus.$on('bchannel-settings', this.dialogSettingsShowOpen)
    this.$eventBus.$on('bchannel-qrcode', this.qrcode)
    this.$eventBus.$on('bchannel-checkpin', this.loyaltyEnterPIN)
    this.$eventBus.$on('bchannel-status', this.updateStatusChange)
  },
  beforeDestroy () {
    this.$eventBus.$off('bchannel-exit', this.exitRequest)
    this.$eventBus.$off('bchannel-today', this.moduleSettingsToday)
    this.$eventBus.$off('bchannel-loyalty', this.moduleSettingsLoyalty)
    this.$eventBus.$off('bchannel-settings', this.dialogSettingsShowOpen)
    this.$eventBus.$off('bchannel-qrcode', this.qrcode)
    this.$eventBus.$off('bchannel-checkpin', this.loyaltyEnterPIN)
    this.$eventBus.$off('bchannel-status', this.updateStatusChange)
    window.removeEventListener('beforeunload', this.channelDisconnect)
    document.querySelector('.q-layout-page-container').classList.remove('q-layout-page-container-padding')
  },
  computed: {
    groupDraggableList () {
      let groupDL = []
      try {
        for (let go in this.product.data.business.groups.order) {
          groupDL.push({
            order: go + 1,
            fixed: false,
            name: this.product.data.business.groups.order[go]
          })
        }
      } catch (e) {}
      return groupDL
    },
    widgetSingleServiceWebLink () {
      return (uuid) => `${document.location.origin}/embed/widget/${this.product.uri}?services=${uuid}`
    },
    isDemoPage () {
      return this.$route.path.includes('-demo')
    },
    // isProduction () {
    //   return window.location.href.startsWith(this.$store.state.app.production_domain)
    // },
    settings_dev_presense: {
      get () {
        return this.$store.state.app.preferences.dev_presense.enabled
      }
    },
    ecosystem_id () {
      return this.ecosystem.ecosystem_id
    },
    ecosystem_id_t () {
      return this.ecosystem.ecosystem_id.toUpperCase()
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFullURI () {
      return [document.location.origin, '/channel/', this.product.data.uri].join('')
    },
    amIOnline () {
      return this.clients.length && this.clients.some(client => client.uuid === this.product.data.uri)
    },
    dialogServicesAddCategories () {
      return Wings.services.categories.list
    },
    dialogServicesAddCategoryComponents () {
      let componentsCategory = this.dialogServicesAddCategory
      let componentsList = []
      if (!componentsCategory) return componentsList
      let componentsServicesList = Wings.services.list[componentsCategory]
      if (componentsServicesList) {
        for (var [cKey, cVal] of Object.entries(componentsServicesList)) {
          componentsList.push({
            label: cVal.id,
            value: cKey,
            rightImage: ['/statics/services/', componentsCategory, '.', cVal.id, '.svg'].join('')
          })
        }
      }
      return componentsList
    },
    productServicesGroupedByCategories () {
      let categories = {}, customCategoryId = '_'
      let productServices = this.product.data.business.services
      let productComponents = this.product.data.business.components
      if (productServices) {
        for (var [sKey, sVal] of Object.entries(productServices)) {
          let categoryId = (sVal.components.length > 1) ? customCategoryId : productComponents[sVal.components[0]].categoryId
          if (!categories[categoryId]) categories[categoryId] = {}
          categories[categoryId][sVal.uuid || sKey] = sVal
        }
      }
      return categories
    },
    currencyIndex () {
      return (this.product.data.business.currency >= 0) ? this.product.data.business.currency : Wings.cost.default.currencyIndex
    },
    currency: {
      get () {
        let currencyIndex = (this.product.data.business.currency >= 0) ? this.product.data.business.currency : Wings.cost.default.currencyIndex
        return Wings.cost.currency.list[currencyIndex]
      },
      set (val) {
        this.product.data.business.currency = val
        this.$set(this.product.data.business, 'currency', val)
        return Wings.cost.currency.list[val]
      }
    },
    HOOOptionsOpen () {
      return this.HOOOptions.slice(0, -1)
    },
    HOOOptionsClose () {
      let options = [], startCollecting = false
      this.HOOOptions.forEach((obj, ix) => {
        if (startCollecting) {
          options.push(obj)
        // } else {
        //   obj.disabled = true
        //   options.push(obj)
        }
        if (obj.value === this.dialogHOOStart) {
          startCollecting = true
        }
      })
      return options
    },
    today_date: {
      get () {
        return moment().format('MMMM Do, YYYY')
      }
    },
    today_day: {
      get () {
        // assume utc_offset in minutes
        let utcOffset = this.product.data.business.timezone.utc_offset
        const date = new Date(Date.now() + utcOffset * 60 * 1000)
        const dayOfWeek = date.getUTCDay()

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return daysOfWeek[dayOfWeek]
      }
    },
    today_current_time: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        return moment(currentTime, 'HH:mm:ss').add(this.clock, 'seconds').format('h:mm A')
      }
    },
    today_time_elapsed: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        currentTime = moment(this.today_current_time, 'h:mm A') // .utcOffset(utcOffset, true)
        const openingTime = moment(this.product.data.business.hoo[this.today_day].open, 'HH:mm').utcOffset(utcOffset)
        const closingTime = moment(this.product.data.business.hoo[this.today_day].close, 'HH:mm').utcOffset(utcOffset)
        const businessDayDuration = closingTime.diff(openingTime, 'seconds')
        const timeElapsed = currentTime.diff(openingTime, 'seconds')
        // const timeRemaining = closingTime.diff(currentTime, 'seconds')
        const percentageElapsed = (timeElapsed / businessDayDuration) * 100
        // const percentageRemaining = (timeRemaining / businessDayDuration) * 100
        return percentageElapsed.toFixed(2)
      }
    },
    requests: {
      get () {
        return this.$store.state.app.requests
      },
      set (val) {
        this.$store.state.app.requests = val
      }
    },
    channel_online: {
      get () {
        return (this.datagroups[0].datafields[0].value.option === 1)
      },
      set (val) {
        let option = (val ? 1 : 0)
        this.datagroups[0].datafields[0].value.option = option
        this.product.data.channel.online = option
        this.channelPublish()
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: ONLINE:${option}`, () => {})
      }
    },
    channel_orders: {
      get () {
        return this.product.data.channel.orders
      },
      set (val) {
        this.product.data.channel.orders = val
        this.channelPublish()
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: ORDERS:${val}`, () => {})
      }
    },
    loyalty_enabled: {
      get () {
        return (this.datagroups[0].datafields[3].value.option === 1)
      },
      set (val) {
        let option = (val ? 1 : 0)
        this.datagroups[0].datafields[3].value.option = option
        this.product.data.channel.loyalty_card = option
        this.channelPublish()
        this.mutateProduct(this.product.id, this.product.data, 'SIGNAL: SEND: ', () => {})
        // clear any outstanding requests
        if (!option) {
          this.channelRequestsClear()
        }
      }
    }
  },
  methods: {
    openURL,
    async copyToClipboard (textToCopy, ev) {
      try {
        await navigator.clipboard.writeText(textToCopy)
        // notify
        this.$q.notify({
          message: 'Copied to clipboard',
          // detail: textToCopy,
          color: 'white',
          position: 'top',
          duration: 800
        })
        // animate (if any)
        if (ev) {
          try {
            let count = 0
            let srcElement = ev.srcElement
            while (srcElement && srcElement.tagName !== 'LABEL' && count++ < 4) srcElement = srcElement.parentElement
            if (srcElement.tagName === 'LABEL') {
              let helperImg = srcElement.querySelector('img[helper]')
              helperImg.classList.add('animated400', 'bounceIn')
              setTimeout(() => helperImg.classList.remove('animated400', 'bounceIn'), 500)
            }
          } catch (e) {}
        }
      } catch (err) {
        console.log(err)
        this.$q.notify({
          message: 'Problem copying to clipboard',
          // detail: textToCopy,
          color: 'protect',
          position: 'top',
          duration: 10000
        })
      }
    },
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    formatValue (num) {
      return nformat(num, '0,0')
    },
    getVenue () {
      try {
        let placeId = this.product.data.place_id
        let venueId = Wings.venues.relations[placeId]
        if (venueId) {
          this.venue = Wings.venues.list[venueId]
          return this.venue
        }
      } catch (e) { }
      return false
    },
    productLocalizationInit () {
      if (this.product.data.business.currency >= 0) return
      console.log('INIT CURRENCY')
      this.product.data.business.currency = Wings.cost.default.currencyIndex
      this.$set(this.product.data.business, 'currency', Wings.cost.default.currencyIndex)
      this.currency = Wings.cost.default.currencyIndex
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, 'BUSINESS.CURRENCY: INIT', () => {})
    },
    productHooInit () {
      if (this.product.data.business.hoo) return
      this.product.data.business.hoo = {}
      for (let i in this.daysOfTheWeek) {
        this.product.data.business.hoo[this.daysOfTheWeek[i]] = {
          open: '8:00',
          close: '23:00',
          is24: false,
          isClosed: false
        }
      }
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, 'BUSINESS.HOO: INIT', () => {})
    },
    productLoyaltyInit () {
      if (this.product.data.business.loyalty) return
      this.product.data.business.loyalty = this.loyaltyDefaultSettings
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, 'BUSINESS.LOYALTY: INIT', () => {})
    },
    clockInit () {
      // toggle the clock every minute
      setInterval(() => {
        this.clock = !this.clock
      }, 1000 * 30)
    },
    getWebsiteDomain (url) {
      if (!url) return false
      var domain
      // find & remove protocol (http, ftp, etc.) and get domain
      if (url.indexOf('://') > -1) {
        domain = url.split('/')[2]
      } else {
        domain = url.split('/')[0]
      }
      // find & remove port number
      domain = domain.split(':')[0]
      if (domain.indexOf('www.') === 0) {
        domain = domain.replace('www.', '')
      }
      return domain
    },
    begin () {
      this.beginFlag = true
      console.log('## begin')
      console.log(this.product)
      setTimeout(() => {
        this.dialogSettingsShowButton = true
      }, 900)
      this.clockInit()
      this.channelInit()
      this.datafieldsInit()
      window.addEventListener('resize', () => {
        this.dialogItemAdjustFit()
      })
      try {
        document.querySelector('#productsEditHeader').classList.add('no-border')
      } catch (e) {}
      setTimeout(() => {
        try {
          document.querySelector('#productsEditHeader').classList.add('no-shadow')
        } catch (e) {}
      }, 400)
      setTimeout(() => {
        try {
          document.querySelector('#productsEditHeader').classList.add('animate-show')
          document.querySelector('#productsEditHeader').classList.remove('opacity-0')
        } catch (e) { } finally {}
      }, 200)
      document.querySelector('.q-layout-page-container').classList.add('q-layout-page-container-padding')
      this.$emit('stopAjaxBar')
      document.querySelector('#q-app > .q-loading-bar.top.bg-primary').hidden = true
      setTimeout(() => {
        if (this.product &&
            this.product.data &&
            this.product.data.channel &&
            this.product.data.channel.online === false
        ) {
          setTimeout(() => (this.channel_online = true), 200)
        }
      }, 400)
      // check if we need to go online
      // console.log('## BEGIN: force offline')
      // let isClosed = this.product.data.business.hoo[this.today_day].isClosed || (this.today_time_elapsed < 0 || this.today_time_elapsed > 100)
      // this.channel_online = !isClosed
    },
    exitRequest () {
      this.$q.dialog({
        title: 'Exit Channel',
        message: 'Leaving the channel will disable loyalty and other interactive features.',
        ok: 'Leave',
        cancel: 'Stay'
      }).then(() => {
        this.exit()
      }).catch(() => {
        // ignore
      })
    },
    exit () {
      this.channelDisconnect()
      this.soundPlay('sheet_up')
      // this.$q.dialog({
      //   title: 'Channel Control',
      //   message: 'Are you sure you want to leave the channel controller? Latest updates will persist until end of day.',
      //   ok: this.$t('YES'),
      //   cancel: this.$t('NO')
      // }).then(() => {
      document.querySelector('#productsEditHeader').classList.remove('animate-show')
      document.querySelector('#appHeader').classList.remove('no-shadow')
      document.querySelector('#appHeader').classList.remove('animate-hide')
      // animate content
      let el = this.$refs['productsEditContent']
      animate.start({
        from: 1,
        to: 0,
        duration: 400,
        easing: easing.accelerate,
        apply (pos) {
          el.style.opacity = pos
        }
      })
      this.dialogSettingsShowButton = false
      setTimeout(() => {
        document.querySelector('#productsEditHeader').style.opacity = 0
        setTimeout(() => {
          this.$router.push('/business')
        }, 200)
      }, 100)
      // }).catch(() => {
      //   //
      // })
    },
    scrolled (scroll) {
      let scrollingTriggerThreshold = 100
      if (Math.abs(scroll.inflexionPosition - scroll.position) >= scrollingTriggerThreshold) {
        if (scroll.direction === 'down' && scroll.position >= scrollingTriggerThreshold) {
          // minimze header
          document.querySelector('#productsEditHeader').classList.add('mini')
        } else if (scroll.direction === 'up') {
          if ((scroll.position <= 200) || (scroll.inflexionPosition - scroll.position >= 300)) {
            // expand header
            document.querySelector('#productsEditHeader').classList.remove('mini')
          }
        }
      }
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#productsEditHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#productsEditHeader').classList.add('no-shadow')
      }
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
          }
        } catch (e) {
          console.log(e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
      }
    },
    cleanURL (url) {
      if (!url) return ''
      return url
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .replace(/\/$/, '')
    },
    changeCurrency () {
      let imgCheckmark = '<img class="q-actionsheet-indicator q-actionsheet-indicator-dense float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let actions = []
      for (var cur in Wings.cost.currency.list) {
        let currencyOption = {
          value: cur,
          label: Wings.cost.currency.list[cur].label + '<br><span>' + Wings.cost.currency.list[cur].name + '</span>'
        }
        if (cur * 1 === this.currencyIndex * 1) {
          currencyOption.label += imgCheckmark
        }
        actions.push(currencyOption)
      }
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: 'Currency Selection',
        actions
      }).then(action => {
        this.product.data.business.currency = action.value
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE BUSINESS CURRENCY: ${action.value} ${Wings.cost.currency.list[action.value].label}`, () => {})
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    HooSelectionDuration (start, close, units) {
      // assume utc_offset in minutes
      let utcOffset = this.product.data.business.timezone.utc_offset / 60
      let currentTime = moment().utcOffset(utcOffset)
      if (currentTime.isDST()) {
        utcOffset += 60
      }
      const openingTime = moment(start, 'HH:mm').utcOffset(utcOffset)
      const closingTime = moment(close, 'HH:mm').utcOffset(utcOffset)
      return closingTime.diff(openingTime, units)
    },
    HooSelectionChange (tabName) {
      console.log(tabName)
      let hoo = this.product.data.business.hoo[tabName.split('-')[2]]
      this.dialogHOOIndex = tabName.split('-')[2]
      this.dialogHOO24h = hoo.is24
      this.dialogHOOClosed = hoo.isClosed
      this.dialogHOOStart = hoo.open
      this.dialogHOOEnd = hoo.close
    },
    HooSelectionChange24h (e) {
      this.product.data.business.hoo[this.dialogHOOIndex].is24 = e
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, `BUSINESS.HOO[${this.dialogHOOIndex}].is24: UPDATE to ${e}`, () => {})
    },
    HooSelectionChangeClosed (e) {
      this.product.data.business.hoo[this.dialogHOOIndex].isClosed = e
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, `BUSINESS.HOO[${this.dialogHOOIndex}].isClosed: UPDATE to ${e}`, () => {})
    },
    HooSelectionChangeOpen (e) {
      this.product.data.business.hoo[this.dialogHOOIndex].open = e
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, `BUSINESS.HOO[${this.dialogHOOIndex}].open: UPDATE to ${e}`, () => {})
      // change close if out-of-bound
      let openFloat = parseFloat(this.dialogHOOStart.replace(':', '.'))
      let closeFloat = parseFloat(this.dialogHOOEnd.replace(':', '.'))
      if (closeFloat <= openFloat) {
        this.dialogHOOEnd = this.HOOOptionsClose[0].value
        this.HooSelectionChangeClose(this.dialogHOOEnd)
      }
      // check for 24 hoo
      this.HooSelectionChange24hCheck()
    },
    HooSelectionChangeClose (e) {
      this.product.data.business.hoo[this.dialogHOOIndex].close = e
      // mutate the product
      this.mutateProduct(this.product.id, this.product.data, `BUSINESS.HOO[${this.dialogHOOIndex}].close: UPDATE to ${e}`, () => {})
      // check for 24 hoo
      this.HooSelectionChange24hCheck()
    },
    HooSelectionChange24hCheck () {
      // change to 24h if its 24h duration
      let is24Duration = this.HooSelectionDuration(this.dialogHOOStart, this.dialogHOOEnd, 'hours') === 24
      if (is24Duration) {
        this.$q.dialog({
          title: 'Full Day Open',
          message: 'It looks like your business is open 24 hours for this day. Would you like to set it as a 24-hour day?',
          ok: this.$t('YES'),
          cancel: this.$t('NO')
        }).then(() => {
          // set to 24 hours
          this.dialogHOO24h = true
          this.HooSelectionChange24h(true)
        }).catch(() => {
          // ignore
        })
      }
    },
    triggerDialogHOO () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogHOOShow = true
    },
    dialogSettingsShowOpen () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogSettingsShow = true
    },
    getStatusChangeData (status) {
      if (!status || status < 1 || status > 6) {
        return {
          label: 'Initializing',
          icons: '/statics/_demo/ellipsis_primary.svg'
        }
      }
      return {
        1: {
          label: 'Be right back',
          icon: '/statics/_demo/time-closed_brb.svg'
        },
        2: {
          label: 'Closed for the day',
          icon: '/statics/_demo/time-closed.svg'
        },
        3: {
          label: 'Closing early',
          icon: '/statics/_demo/time-closing_early.svg'
        },
        4: {
          label: 'Limited hours',
          icon: '/statics/_demo/time-limited.svg'
        },
        5: {
          label: 'Delayed opening',
          icon: '/statics/_demo/time-delayed_opening.svg'
        },
        6: {
          label: 'Lunch break',
          icon: '/statics/_demo/time-lunch_break.svg'
        }
      }[status]
    },
    updateStatusChange () {
      if (this.product.data.business.hoo[this.today_day].isClosed || (this.today_time_elapsed < 0 || this.today_time_elapsed > 100)) {
        this.$q.notify({
          message: 'Closed for Announcements',
          detail: 'Operational Update',
          color: 'white',
          textColor: 'protect',
          position: 'top',
          icon: 'ion-close-circle',
          duration: 800
        })
        return
      }
      let imgCheckmark = '<img class="q-actionsheet-indicator q-actionsheet-indicator-dense float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let orderStatus = this.channel_orders
      this.soundPlay('entry_actionsheet')
      let actions = []
      for (let act = 1; act <= 6; act++) {
        actions.push({
          value: act,
          label: this.getStatusChangeData(act).label + (orderStatus === act ? imgCheckmark : ''),
          avatar: this.getStatusChangeData(act).icon
        })
      }
      actions.push({})
      actions.push({
        value: false,
        color: 'protect',
        label: 'Remove Change',
        icon: 'ion-trash'
      })
      console.log(actions)
      this.$q.actionSheet({ title: 'Status Change', actions }).then(action => {
        this.soundPlay('sheet_drop')
        if (action.value === false) {
          this.channel_orders = 0
          return
        }
        this.channel_orders = ~~action.value
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    share () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogShareShow = !this.dialogShareShow
    },
    moduleStatsLoyaltyToday () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogModuleStatsLoyaltyTodayShow = !this.dialogModuleStatsLoyaltyTodayShow
      if (this.dialogModuleStatsLoyaltyTodayShow) {
        // process stats
        this.dialogModuleStatsLoyaltyTodayData = null
        // get request
        let pid = this.product.id
        axiosLIO.get(`/nservice/stat/today/${pid}/loyalty/reward`).then((res) => {
          console.log(`:: /nservice/stat/today/${pid}/loyalty/reward`)
          console.log(res)
          this.dialogModuleStatsLoyaltyTodayData = res.data.data
        }).catch((e) => {
          this.dialogModuleStatsLoyaltyTodayData = false
        })
      }
    },
    moduleSettingsToday () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogModuleSettingsTodayShow = !this.dialogModuleSettingsTodayShow
    },
    moduleSettingsLoyalty () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogModuleSettingsLoyaltyShow = !this.dialogModuleSettingsLoyaltyShow
    },
    moduleSettingsLoyaltyStamps () {
      if (this.product.master_uri) return
      this.soundPlay('entry_actionsheet')
      let actions = [{
        label: '11',
        icon: this.product.data.business.loyalty.stamps === 11 ? 'ion-checkmark' : ''
      }, {
        label: '10',
        icon: this.product.data.business.loyalty.stamps === 10 ? 'ion-checkmark' : ''
      }, {
        label: '9',
        icon: this.product.data.business.loyalty.stamps === 9 ? 'ion-checkmark' : ''
      }, {
        label: '8',
        icon: this.product.data.business.loyalty.stamps === 8 ? 'ion-checkmark' : ''
      }, {
        label: '7',
        icon: this.product.data.business.loyalty.stamps === 7 ? 'ion-checkmark' : ''
      }]
      this.$q.actionSheet({ title: 'Required Stamps', actions }).then(action => {
        this.product.data.business.loyalty.stamps = ~~action.label
        this.soundPlay('sheet_drop')
        // mutate the product
        this.mutateProduct(this.product.id, this.product.data, `BUSINESS.LOYALTY.STAMPS: UPDATE to ${~~action.label}`, () => {})
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    dialogSpacesManage () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesSpacesShow = !this.dialogServicesSpacesShow
    },
    dialogGroupsHide () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesGroupsShow = false
    },
    dialogGroupsShow (selectionMode) {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesGroupsSelectionMode = selectionMode || false
      this.dialogServicesGroupsShow = true
    },
    dialogGroupShow (groupId) {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesGroupId = groupId
      this.dialogServicesGroupShow = true
    },
    dialogGroupHide () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesGroupShow = false
      setTimeout(() => { this.dialogServicesGroupId = null }, 10)
    },
    groupChangeName () {
      this.$q.dialog({
        title: 'Set Group Name',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.groups.list[this.dialogServicesGroupId].title,
          type: 'text'
        }
      }).then(data => {
        // update name
        let name = `${data}`.trim()
        this.product.data.business.groups.list[this.dialogServicesGroupId].title = name
        this.$set(this.product.data.business.groups.list[this.dialogServicesGroupId], 'title', name)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE GROUP NAME`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    groupChangeDescription () {
      this.$q.dialog({
        title: 'Set Group Description',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.groups.list[this.dialogServicesGroupId].description,
          type: 'text'
        }
      }).then(data => {
        // update description
        let description = `${data}`.trim()
        this.product.data.business.groups.list[this.dialogServicesGroupId].description = description
        this.$set(this.product.data.business.groups.list[this.dialogServicesGroupId], 'description', description)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE GROUP DESCRIPTON`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    groupChangePrivacy (e) {
      if (this.dialogServicesGroupId) {
        this.product.data.business.groups.list[this.dialogServicesGroupId].private = e
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE GROUP PRIVACY: G:${this.dialogServicesGroupId}`, () => {})
      }
    },
    groupAddService () {
      // utilize a general services list selector
      let services = this.product.data.business.services
      let servicesList = []
      for (let sUUID in services) {
        if (!(this.product.data.business.groups.list[this.dialogServicesGroupId].services.indexOf(sUUID) >= 0)) {
          servicesList.push(sUUID)
        }
      }
      this.dialogServicesSelectorList = servicesList
      this.dialogServicesSelectorSelection = null
      this.dialogServicesUpdateSelection = null
      this.dialogServicesSelectorListCallback = () => {
        // add dialogServicesSelectorSelection to dialogServicesUpdateSelection.dependencies
        if (!this.product.data.business.groups.list[this.dialogServicesGroupId].services) {
          this.product.data.business.groups.list[this.dialogServicesGroupId].services = []
        }
        this.product.data.business.groups.list[this.dialogServicesGroupId].services.push(this.dialogServicesSelectorSelection)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE GROUP SERVICES: G:${this.dialogServicesGroupId} S:${this.dialogServicesSelectorSelection}`, () => {})
        // reset selection
        this.dialogServicesSelectorSelection = null
      }
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesSelectorShow = true
    },
    groupRemoveService (serviceUUID) {
      let servicesList = this.product.data.business.groups.list[this.dialogServicesGroupId].services
      let serviceIndex = servicesList.findIndex(serv => serv === serviceUUID)
      this.product.data.business.groups.list[this.dialogServicesGroupId].services.splice(serviceIndex, 1)
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE GROUP SERVICE: G:${this.dialogServicesGroupId} S:${serviceUUID}`, () => {})
    },
    groupRemoveDialog () {
      this.$q.dialog({
        title: 'Remove Group',
        message: `Are you sure you want to remove this group?`,
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // hide dialog
        this.dialogGroupHide()
        // delete
        this.groupRemove()
      }).catch(() => {
        // ignore
      })
    },
    groupRemove () {
      // remove from order
      let groupsOrder = this.product.data.business.groups.order
      let groupIndex = groupsOrder.findIndex(groupId => groupId === this.dialogServicesGroupId)
      this.product.data.business.groups.order.splice(groupIndex, 1)
      // remove from list
      delete this.product.data.business.groups.list[this.dialogServicesGroupId]
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE GROUP: G:${this.dialogServicesGroupId}`, () => {})
    },
    groupReorderGroups () {
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REORDER GROUPS`, () => {})
    },
    groupCreate () {
      this.$q.dialog({
        title: 'Group Name',
        message: '',
        ok: this.$t('CREATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          type: 'text'
        }
      }).then(data => {
        // create group
        let name = `${data}`.trim()
        let uuid = this.$guid.generate()
        let group = {
          uuid: uuid,
          title: name,
          description: '',
          count: 0,
          private: false,
          services: []
        }
        this.product.data.business.groups.list[uuid] = group
        this.product.data.business.groups.order.push(uuid)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE GROUPS ADD: G:${uuid}`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    dialogServicesAdd () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesAddShow = !this.dialogServicesAddShow
    },
    dialogComponentsAdd (append) {
      if (append === true) {
        this.dialogComponentStore = true
        console.log(':: dialogComponentsAdd: dialogComponentStore: ', this.dialogComponentStore)
      }
      if (this.dialogServicesAddComponents.length >= this.dialogServicesAddComponentsLimit) return
      this.toolbarShadowOnOverscrollClear()
      this.dialogComponentsAddShow = !this.dialogComponentsAddShow
    },
    componentAdd (componentId, categoryId) {
      let componentListId = this.$guid.generate()
      let componentData = { componentListId, componentId, categoryId }
      if (this.dialogComponentStore) {
        let serviceUUID = this.dialogServicesUpdateSelection.uuid
        // updated the selected feature list
        let componentData = {
          uuid: componentListId,
          status: null,
          categoryId,
          componentId,
          updated: new Date().getTime()
        }
        this.product.data.business.components[componentListId] = componentData
        this.product.data.business.services[serviceUUID].components.push(componentListId)
        // refresh the service data
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        this.dialogComponentStore = false
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE_ADD_COMPONENT: S:${serviceUUID} C:${componentListId}`, () => {})
        return
      }
      this.dialogServicesAddComponents.push(componentData)
      // if we are creating a single component service, finalize it
      if (this.dialogServicesCreateComponent) this.serviceComponentCreateProcess()
    },
    componentRemove (componentListId) {
      let componentListIndex = this.dialogServicesAddComponents.findIndex(comp => comp.componentListId === componentListId)
      if (componentListIndex <= this.dialogServicesAddComponentsIconIndex) {
        this.dialogServicesAddComponentsIconIndex = 0
      }
      this.dialogServicesAddComponents.splice(componentListIndex, 1)
    },
    componentsGroupedByComponents (services) {
      let categories = {}
      for (var [sKey, sVal] of Object.entries(services)) {
        let componentUUID = sVal.components[0]
        if (!this.product.data.business.components[componentUUID]) continue
        let componentId = this.product.data.business.components[componentUUID].componentId
        if (!categories[componentId]) categories[componentId] = {}
        categories[componentId][sKey] = sVal
      }
      return categories
    },
    computeServiceProperties (serviceUUID) {
      let service = _.cloneDeep(this.product.data.business.services[serviceUUID])
      if (!service) return null
      // add service data
      let serviceComputed = service
      // add private details
      serviceComputed.private = serviceComputed.private || false
      serviceComputed.requests = serviceComputed.requests || false
      serviceComputed.partial = serviceComputed.partial || false
      serviceComputed.isNew = serviceComputed.isNew || false
      serviceComputed.group = serviceComputed.group || false
      serviceComputed.cost = serviceComputed.cost === 0 ? 0 : (serviceComputed.cost || false)
      // add space details
      serviceComputed.spaceDetails = {
        spaceIndex: null // All
      }
      // add set details
      serviceComputed.setDetails = {
        isSet: serviceComputed.components && serviceComputed.components.length > 1,
        totalComponents: serviceComputed.components.length
      }
      // add icon details
      serviceComputed.iconDetails = this.product.data.business.components[service.icon]
      // add categories details
      serviceComputed.categoriesDetails = {}
      // add status details
      serviceComputed.statusDetails = {
        components: [],
        status: null,
        updated: 0
      }
      // add dependencies details
      let dependencies = serviceComputed.dependencies || []
      serviceComputed.dependencies = dependencies
      serviceComputed.dependenciesDetails = {
        dependencies: dependencies
      }
      // add dependents details
      serviceComputed.dependentsDetails = this.computeDependents(serviceUUID)
      // add components details
      serviceComputed.componentsDetails = {}
      for (let componentIndex in serviceComputed.components) {
        let componentUUID = serviceComputed.components[componentIndex]
        let componentData = this.product.data.business.components[componentUUID]
        componentData.isDev = componentData.isDev || false
        serviceComputed.componentsDetails[componentUUID] = componentData
        // update category details
        if (!serviceComputed.categoriesDetails[componentData.categoryId]) {
          serviceComputed.categoriesDetails[componentData.categoryId] = []
        }
        serviceComputed.categoriesDetails[componentData.categoryId].push(componentData.componentId)
        // update status details
        serviceComputed.statusDetails.components.push(componentData.status)
        if (serviceComputed.statusDetails.updated < componentData.updated) {
          serviceComputed.statusDetails.updated = componentData.updated
        }
      }
      serviceComputed.statusDetails.status = this.serviceStatus(serviceUUID)
      return serviceComputed
    },
    componentUpdate (serviceUUID) {
      if (serviceUUID) {
        // set global variables to edit variables
        this.dialogServicesUpdateSelection = null
        let service = _.cloneDeep(this.product.data.business.services[serviceUUID])
        console.log(service)
        if (service) {
          // add service data
          this.dialogServicesUpdateSelection = service
          // add private details
          this.dialogServicesUpdateSelection.private = service.private || false
          this.dialogServicesUpdateSelection.requests = service.requests || false
          this.dialogServicesUpdateSelection.cost = service.cost === 0 ? 0 : (service.cost || false)
          this.dialogServicesUpdateSelection.partial = service.partial || false
          this.dialogServicesUpdateSelection.isNew = service.isNew || false
          this.dialogServicesUpdateSelection.group = service.group || false
          // add space details
          this.dialogServicesUpdateSelection.spaceDetails = {
            spaceIndex: null // All
          }
          // add set details
          this.dialogServicesUpdateSelection.setDetails = {
            isSet: this.dialogServicesUpdateSelection.components && this.dialogServicesUpdateSelection.components.length > 1,
            totalComponents: this.dialogServicesUpdateSelection.components.length
          }
          // add icon details
          this.dialogServicesUpdateSelection.iconDetails = this.product.data.business.components[service.icon]
          // add categories details
          this.dialogServicesUpdateSelection.categoriesDetails = {}
          // add status details
          this.dialogServicesUpdateSelection.statusDetails = {
            components: [],
            status: null,
            updated: 0,
            private: service.private || false,
            actions: service.requests || false,
            cost: service.cost === 0 ? 0 : (service.cost || false),
            partial: service.partial || false,
            isNew: service.isNew || false,
            group: service.group || false
          }
          // add dependencies details
          let dependencies = this.dialogServicesUpdateSelection.dependencies || []
          this.dialogServicesUpdateSelection.dependencies = dependencies
          this.dialogServicesUpdateSelection.dependenciesDetails = {
            dependencies: dependencies
          }
          // add dependents details
          this.dialogServicesUpdateSelection.dependentsDetails = this.computeDependents(serviceUUID)
          // add components details
          this.dialogServicesUpdateSelection.componentsDetails = {}
          for (let componentIndex in this.dialogServicesUpdateSelection.components) {
            let componentUUID = this.dialogServicesUpdateSelection.components[componentIndex]
            let componentData = this.product.data.business.components[componentUUID]
            this.dialogServicesUpdateSelection.componentsDetails[componentUUID] = componentData
            // update category details
            if (!this.dialogServicesUpdateSelection.categoriesDetails[componentData.categoryId]) {
              this.dialogServicesUpdateSelection.categoriesDetails[componentData.categoryId] = []
            }
            this.dialogServicesUpdateSelection.categoriesDetails[componentData.categoryId].push(componentData.componentId)
            // update status details
            this.dialogServicesUpdateSelection.statusDetails.components.push(componentData.status)
            if (this.dialogServicesUpdateSelection.statusDetails.updated < componentData.updated) {
              this.dialogServicesUpdateSelection.statusDetails.updated = componentData.updated
            }
          }
          this.dialogServicesUpdateSelection.statusDetails.status = this.serviceStatus(serviceUUID)
        }
      }
      if (this.dialogServicesStatusUpdateShow && serviceUUID) {
        this.dialogServicesStatusUpdateShow = false
        setTimeout(() => {
          this.toolbarShadowOnOverscrollClear()
          this.dialogServicesStatusUpdateShow = true
        }, 400)
        return
      }
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesStatusUpdateShow = !this.dialogServicesStatusUpdateShow
    },
    serviceLastUpdate (serviceUUID) {
      let updated = 0
      let service = this.product.data.business.services[serviceUUID]
      for (let componentIndex in service.components) {
        let componentUUID = service.components[componentIndex]
        let componentData = this.product.data.business.components[componentUUID]
        if (updated < componentData.updated) {
          updated = componentData.updated
        }
      }
      for (let dependentIndex in service.dependencies) {
        let dependentUUID = service.dependencies[dependentIndex]
        let dependentUpdated = this.serviceLastUpdate(dependentUUID)
        if (updated < dependentUpdated) {
          updated = dependentUpdated
        }
      }
      return updated
    },
    computeDependents (serviceUUID) {
      // there are direct and indirect dependents
      // direct: find the UUID in any service
      // indirect: add up all UUIDs in direct services
      let dependents = {
        direct: [],
        indirect: []
      }
      for (let sUUID in this.product.data.business.services) {
        if (sUUID === serviceUUID) continue // ignore ourself
        let service = this.product.data.business.services[sUUID]
        // console.log(sUUID, service.dependencies)
        for (let depsIndex in service.dependencies) {
          let depsUUID = service.dependencies[depsIndex]
          if (depsUUID === serviceUUID) {
            dependents.direct.push(sUUID)
            dependents.indirect = this.computeDependents(sUUID).direct
            // try to drill further
            for (let indirectIndex in dependents.indirect) {
              let indirectUUID = dependents.indirect[indirectIndex]
              dependents.indirect = [...dependents.indirect, ...this.computeDependents(indirectUUID).direct, ...this.computeDependents(indirectUUID).indirect]
            }
          }
        }
      }
      return dependents
    },
    computeListOfDependentsServices (serviceUUID) {
      // Filter the list services by using the following rules:
      // 1. cannot be *self*
      // 2. ignore *self's* own dependencies
      // 3. ignore services where their dependencies point back to *self*
      // 4. ignore services where their dependents (direct or indirect) doesn't point back to *self*
      // 5. ignore services where *self* is not in its own dependencies's direct or indirect dependents
      let potentials = []
      let services = this.product.data.business.services
      let ownDependencies = services[serviceUUID].dependencies || []
      let ownDependents = this.computeDependents(serviceUUID)
      for (let sUUID in services) {
        if (sUUID === serviceUUID) continue // (rule 1)
        if (ownDependencies.indexOf(sUUID) >= 0) continue // (rule 2)
        let serviceDependencies = services[sUUID].dependencies || []
        if (serviceDependencies.indexOf(serviceUUID) >= 0) continue // rule (3)
        let serviceDependents = this.computeDependents(sUUID)
        if (serviceDependents.direct.indexOf(serviceUUID) >= 0 || serviceDependents.indirect.indexOf(serviceUUID) >= 0) continue // rule (4)
        if (ownDependents.direct.indexOf(sUUID) >= 0 || ownDependents.indirect.indexOf(sUUID) >= 0) continue // rule (5)
        // add as a potential
        potentials.push(sUUID)
      }
      return potentials
    },
    dialogDependencyAdd () {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      let potentialDependencies = this.computeListOfDependentsServices(serviceUUID)
      console.log(potentialDependencies)
      // utilize a general services list selector
      this.dialogServicesSelectorList = potentialDependencies
      this.dialogServicesSelectorSelection = null
      this.dialogServicesSelectorListCallback = () => {
        // add dialogServicesSelectorSelection to dialogServicesUpdateSelection.dependencies
        if (!this.product.data.business.services[this.dialogServicesUpdateSelection.uuid].dependencies) {
          this.product.data.business.services[this.dialogServicesUpdateSelection.uuid].dependencies = []
        }
        this.product.data.business.services[this.dialogServicesUpdateSelection.uuid].dependencies.push(this.dialogServicesSelectorSelection)
        this.dialogServicesUpdateSelection.dependencies.push(this.dialogServicesSelectorSelection)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE_ADD_DEPENDENCY: S:${this.dialogServicesSelectorSelection.uuid} D:${serviceUUID}`, () => {})
        // reset selection
        this.dialogServicesSelectorSelection = null
      }
      this.toolbarShadowOnOverscrollClear()
      this.dialogServicesSelectorShow = true
    },
    dialogDependencySelect (serviceUUID) {
      this.dialogServicesSelectorSelection = serviceUUID
      this.dialogServicesSelectorShow = false
      setTimeout(() => {
        this.dialogServicesSelectorList = []
        this.dialogServicesSelectorListCallback()
      }, 1)
    },
    serviceRemoveDependencyDialog (serviceUUID) {
      this.$q.dialog({
        title: 'Remove Dependency',
        message: `Are you sure you want to remove this dependency?`,
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
        this.serviceRemoveDependencyProcess(this.dialogServicesUpdateSelection.uuid, serviceUUID)
      }).catch(() => {
        // ignore
      })
    },
    serviceRemoveDependencyProcess (serviceUUID, dependencyUUID) {
      // remove dependency from services dependencies array
      this.product.data.business.services[serviceUUID].dependencies.splice(
        this.product.data.business.services[serviceUUID].dependencies.indexOf(dependencyUUID), 1
      )
      this.product.data.business.services[serviceUUID].dependenciesDetails = {
        dependencies: this.product.data.business.services[serviceUUID].dependencies
      }
      // refresh the "selected node"
      this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE DEPENDENCY: S:${serviceUUID} D:${dependencyUUID}`, () => {})
    },
    serviceChangePrivacy (e) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      if (serviceUUID) {
        this.product.data.business.services[serviceUUID].private = e
        // this.dialogServicesUpdateSelection.private = e
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE PRIVACY: S:${serviceUUID}`, () => {})
      }
    },
    serviceChangeRequests (e) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      if (serviceUUID) {
        this.product.data.business.services[serviceUUID].requests = e
        // this.dialogServicesUpdateSelection.private = e
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE REQUESTS: S:${serviceUUID}`, () => {})
      }
    },
    serviceChangePartial (e) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      if (serviceUUID) {
        this.product.data.business.services[serviceUUID].partial = e
        // this.dialogServicesUpdateSelection.private = e
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE PARTIAL: S:${serviceUUID}`, () => {})
      }
    },
    serviceChangeIsNew (e) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      if (serviceUUID) {
        this.product.data.business.services[serviceUUID].isNew = e
        // this.dialogServicesUpdateSelection.private = e
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE ISNEW: S:${serviceUUID}`, () => {})
      }
    },
    serviceChangeCost (e) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      if (serviceUUID) {
        this.product.data.business.services[serviceUUID].cost = e
        // this.dialogServicesUpdateSelection.cost = e
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE COST: S:${serviceUUID}`, () => {})
      }
    },
    serviceChangeIcon (componentUUID) {
      let serviceUUID = this.dialogServicesUpdateSelection.uuid
      this.product.data.business.services[serviceUUID].icon = componentUUID
      this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE ICON: S:${serviceUUID}`, () => {})
    },
    serviceChangeCostValue () {
      this.$q.dialog({
        title: 'Set Price',
        message: 'Leave blank to remove cost',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.dialogServicesUpdateSelection.cost,
          type: 'number'
        }
      }).then(data => {
        // update cost
        let cost = `${data}`.trim()
        cost = cost.length ? cost * 1 : ''
        this.dialogServicesUpdateSelection.cost = cost
        this.serviceChangeCost(cost)
      }).catch(() => {
        // ignore
      })
    },
    channelChangePhone () {
      this.$q.dialog({
        title: 'Set Phone',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.phone,
          type: 'text'
        }
      }).then(data => {
        // update phone
        let phone = `${data}`.trim()
        this.product.data.business.phone = phone
        this.$set(this.product.data.business, 'phone', phone)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE CHANNEL PHONE`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    channelChangeWebsite () {
      this.$q.dialog({
        title: 'Set Website',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.website,
          type: 'text'
        }
      }).then(data => {
        // update website
        let website = `${data}`.trim()
        this.product.data.business.website = website
        this.$set(this.product.data.business, 'website', website)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE CHANNEL WEBSITE`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    channelChangeDescription () {
      this.$q.dialog({
        title: 'Set Description',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.metas.description,
          type: 'text'
        }
      }).then(data => {
        // update description
        let description = `${data}`.trim()
        this.product.data.business.metas.description = description
        this.$set(this.product.data.business.metas, 'description', description)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE CHANNEL DESCRIPTION`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    channelChangeName () {
      let oldName = this.product.data.business.name
      this.$q.dialog({
        title: 'Set Name',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.product.data.business.name,
          type: 'text'
        }
      }).then(data => {
        // update name
        let name = `${data}`.trim()
        if (!name || name.length === 0) {
          this.product.data.business.name = oldName
          return
        }
        this.product.data.business.name = name
        this.$set(this.product.data.business, 'name', name)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE CHANNEL NAME`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    serviceChangeLabel () {
      this.$q.dialog({
        title: 'Set Label',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.dialogServicesUpdateSelection.label,
          type: 'text'
        }
      }).then(data => {
        // update label
        let label = `${data}`.trim()
        this.dialogServicesUpdateSelection.label = label
        let serviceUUID = this.dialogServicesUpdateSelection.uuid
        this.product.data.business.services[serviceUUID].label = label
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE LABEL: S:${serviceUUID}`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    serviceChangeDescription () {
      this.$q.dialog({
        title: 'Set Description',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.dialogServicesUpdateSelection.description,
          type: 'text'
        }
      }).then(data => {
        // update description
        let description = `${data}`.trim()
        this.dialogServicesUpdateSelection.description = description
        let serviceUUID = this.dialogServicesUpdateSelection.uuid
        this.product.data.business.services[serviceUUID].description = description
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE DESCRIPTION: S:${serviceUUID}`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    serviceChangeRefId () {
      this.$q.dialog({
        title: 'Set ID',
        message: '',
        ok: this.$t('UPDATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: this.dialogServicesUpdateSelection.refId,
          type: 'text'
        }
      }).then(data => {
        // update label
        let refId = `${data}`.trim()
        this.dialogServicesUpdateSelection.refId = refId
        let serviceUUID = this.dialogServicesUpdateSelection.uuid
        this.product.data.business.services[serviceUUID].refId = refId
        this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE SERVICE REFID: S:${serviceUUID}`, () => {})
      }).catch(() => {
        // ignore
      })
    },
    serviceStatus (serviceUUID) {
      let status = null, componentsStatus = []
      let service = this.product.data.business.services[serviceUUID]
      let expectStatusChange = service.status >= 0
      let statusOld = null, statusNew = null
      if (expectStatusChange) {
        statusOld = service.status
      }
      // compute components
      for (let componentIndex in service.components) {
        let componentUUID = service.components[componentIndex]
        componentsStatus.push(this.product.data.business.components[componentUUID].status)
      }
      // compute dependencies
      for (let dependencyIndex in service.dependencies) {
        let serviceUUID = service.dependencies[dependencyIndex]
        componentsStatus.push(this.serviceStatus(serviceUUID))
      }
      status = componentsStatus.some(c => c === null) ? null : Math.max(...componentsStatus)
      if (expectStatusChange) {
        statusNew = status
        if (statusNew !== statusOld) {
          this.logStatusChange(true, 2, serviceUUID, statusOld, statusNew)
        }
      }
      this.product.data.business.services[serviceUUID].status = status
      return status
    },
    serviceComponentCreate () {
      // clear components
      this.serviceCreationReset()
      // indicate creating process
      this.dialogServicesCreateComponent = true
      this.dialogComponentsAdd()
    },
    serviceComponentCreateProcess () {
      this.serviceCreate()
    },
    serviceCreationReset () {
      this.dialogServicesAddComponents = []
      this.dialogServicesAddComponentsIconIndex = 0
      this.dialogServicesAddId = ''
      this.dialogServicesAddLabel = ''
      this.dialogServicesAddCreating = false
      this.dialogServicesCreateComponent = false
    },
    serviceCreate () {
      this.dialogServicesAddCreating = true
      let service = {}, componentsUUIDs = [], components = {}
      for (let i in this.dialogServicesAddComponents) {
        let uuid = this.dialogServicesAddComponents[i].componentListId
        componentsUUIDs.push(uuid)
        components[uuid] = {
          uuid,
          status: null,
          categoryId: this.dialogServicesAddComponents[i].categoryId,
          componentId: this.dialogServicesAddComponents[i].componentId,
          updated: new Date().getTime()
        }
      }
      service = {
        uuid: this.$guid.generate(),
        label: this.dialogServicesAddLabel,
        description: '',
        refId: this.dialogServicesAddId ? this.dialogServicesAddId : '',
        icon: this.dialogServicesAddComponents[this.dialogServicesAddComponentsIconIndex].componentListId,
        components: componentsUUIDs
      }
      // add it to the JSON structure
      this.product.data.business.services[service.uuid] = service
      this.product.data.business.components = {...this.product.data.business.components, ...components}
      // db.create
      axiosLIO.post(`/product/${this.product.data.uri}/service/create`, {
        service: {
          uuid: service.uuid,
          components: Object.values(components)
        }
      })
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: NEW SERVICE: ${service.uuid}`, () => {})
      // close dialog
      if (!this.dialogServicesCreateComponent) this.dialogServicesAdd()
      // clear vars
      this.serviceCreationReset()
      // if this is not a station, show the modify dialog
      if (componentsUUIDs.length === 1) {
        setTimeout(() => {
          this.componentUpdate(service.uuid)
        }, 400)
      }
    },
    duplicateService () {
      this.dialogServicesDuplicating = true
      // clone a new service from the current one
      let oldServiceUUID = this.dialogServicesUpdateSelection.uuid
      let newService = _.cloneDeep(this.dialogServicesUpdateSelection)
      // generate a new service UUID
      let newServiceUUID = this.$guid.generate()
      newService.uuid = newServiceUUID
      // iterate through the components
      let newCompDetails = {}
      let mapCompUUIDs = {}
      for (let oldCompUUID in newService.componentsDetails) {
        let oldCompData = newService.componentsDetails[oldCompUUID]
        // generate a new component UUID
        let newCompUUID = this.$guid.generate()
        // create a key/value map of { old: new }
        mapCompUUIDs[oldCompUUID] = newCompUUID
        // replace this component with a new UUID
        oldCompData.uuid = newCompUUID
        // store the new comp details
        newCompDetails[newCompUUID] = _.cloneDeep(oldCompData)
        newCompDetails[newCompUUID].uuid = newCompUUID
      }
      // replace components with only new ones
      newService.components = Object.keys(newCompDetails)
      newService.componentsDetails = newCompDetails
      // update icon
      let oldIconUUID = newService.icon
      let newIconUUID = mapCompUUIDs[oldIconUUID]
      newService.icon = newIconUUID
      newService.iconDetails.uuid = newIconUUID
      // update label
      newService.label += ' Copy'

      // add new components to payload
      this.product.data.business.components = {...this.product.data.business.components, ...newCompDetails}
      // add new services to payload
      this.product.data.business.services[newServiceUUID] = newService
      // db.create
      axiosLIO.post(`/product/${this.product.data.uri}/service/create`, {
        service: {
          uuid: newServiceUUID,
          status: newService.status,
          components: Object.values(newCompDetails)
        }
      })
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: DUPLICATE SERVICE: FROM:${oldServiceUUID} S:${newServiceUUID}`, () => {})

      // reset selection and close dialog
      setTimeout(() => {
        this.dialogServicesUpdateSelection = null
        this.componentUpdate()
        this.dialogServicesDuplicating = false
        // show the modify dialog
        setTimeout(() => this.componentUpdate(newServiceUUID), 400)
      }, 400)
    },
    getServiceName (service) {
      return service.label || Wings.services.list[service.iconDetails.categoryId][service.iconDetails.componentId].name
    },
    getComponentName (component) {
      return component.label || Wings.services.list[component.categoryId][component.componentId].name
    },
    getServiceCategoryLabel (serviceCategory) {
      const category = Wings.services.categories.list.find(category => category.value === serviceCategory)
      return category ? category.label : serviceCategory
    },
    removeComponentDialog (service, component) {
      // delete feature
      this.$q.dialog({
        title: 'Remove Feature',
        message: `Are you sure you want to remove this feature?`,
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
        this.removeComponent(service.uuid, component.uuid)
      }).catch(() => {
        // ignore
      })
    },
    getComponentDescriptors (component) {
      return Wings.services.list[component.categoryId][component.componentId].descriptors || Wings.services.defaults.descriptors
    },
    changeComponentStatus (component, serviceUUID) {
      let _d = this.getComponentDescriptors(component)
      console.log(_d)
      let imgCheckmark = '<img class="q-actionsheet-indicator q-actionsheet-indicator-dense float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let actions = [{
        label: _d.availability['0'].label + (component.status === 0 ? imgCheckmark : '') +
          `<span class="block font-size-85p line-height-sm text-shallow text-weight-medium">${_d.availability['0'].sublabel}</span>`,
        icon: 'ion-checkmark-circle',
        color: 'educate',
        value: 0
      }, {
        label: _d.availability['1'].label + (component.status === 1 ? imgCheckmark : '') +
          `<span class="block font-size-85p line-height-sm text-shallow text-weight-medium">${_d.availability['1'].sublabel}</span>`,
        icon: 'ion-alert',
        color: 'attention',
        value: 1
      }, {
        label: _d.availability['2'].label + (component.status === 2 ? imgCheckmark : '') +
          `<span class="block font-size-85p line-height-sm text-shallow text-weight-medium">${_d.availability['2'].sublabel}</span>`,
        icon: 'ion-close-circle',
        color: 'protect',
        value: 2
      }, {
        label: _d.availability['3'].label + (component.status === 3 ? imgCheckmark : '') +
          `<span class="block font-size-85p line-height-sm text-shallow text-weight-medium">${_d.availability['3'].sublabel}</span>`,
        icon: 'ion-code-working',
        color: 'purple-l2',
        value: 3
      }]
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: `modify ${this.getComponentName(component)}`,
        actions
      }).then(action => {
        if (action.value === false) {
          this.removeComponentDialog(this.dialogServicesUpdateSelection, component)
        } else {
          let statusOld = this.product.data.business.components[component.uuid].status
          let statusNew = action.value
          // log.status
          this.logStatusChange(false, 0, component.uuid, statusOld, statusNew)
          this.product.data.business.components[component.uuid].status = action.value
          this.product.data.business.components[component.uuid].updated = new Date().getTime()
          serviceUUID = serviceUUID || this.dialogServicesUpdateSelection.uuid
          this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
          // publish status change to the channel
          this.channelPublish({
            uuid: component.uuid,
            status: action.value
          })
          // db.update
          this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: UPDATE COMPONENT STATUS: S:${serviceUUID} C:${component.uuid}`, () => {})
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    logStatusChange (isService, changedBy, uuid, statusOld, statusNew) {
      if (!this.beginFlag) return
      let isComponent = !isService
      let changedByType = changedBy === 0 ? 'USER' : (changedBy === 1 ? 'API' : 'SYSTEM')
      let updated = new Date().getTime()
      console.log(`## ${uuid} [${isComponent ? 'COMPONENT' : 'SERVICE'}] CHANGED [BY ${changedByType}]: ${statusOld} > ${statusNew}`)
      this.logStatusChangeQueue({
        isService,
        changedByType,
        uuid,
        statusOld,
        statusNew,
        updated
      })
    },
    // log collects as many log requests within 400ms before requesting a log
    logStatusChangeQueue (log) {
      console.log('## logStatus.Queue: ', log.changedByType)
      // add to the queue
      this.logQueue.push(log)
      // post the queue
      if (!this.logTimer) {
        console.log('## logStatus.Timer')
        this.logTimer = setTimeout(() => {
          this.logStatusChangePost()
        }, 400)
      }
    },
    logStatusChangePost () {
      // go through the current queue and post
      console.log('## logStatus.Post: ', this.logQueue)
      // form the query json
      let logQueueRequest = {
        logs: this.logQueue
      }
      // clear the queue
      this.logQueue = []
      clearTimeout(this.logTimer)
      this.logTimer = null
      // send the request
      axiosLIO.post(`/product/${this.product.data.uri}/log`, logQueueRequest)
    },
    removeAllServices () {
      return this.$q.dialog({
        title: 'Delete All Service',
        message: 'Deleting all services (stations, features, and dependencies) is irreversible. Would you like to continue?',
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // remove all services and components completely
        this.product.data.business.services = {}
        this.product.data.business.components = {}
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: RESET SERVICES`, () => {})
      }).catch(() => {})
    },
    removeService (serviceUUID) {
      // check for dependencies
      let relatedServices = this.computeDependents(serviceUUID).direct
      return this.$q.dialog({
        title: 'Delete Service',
        message: 'Deleting this service will remove all of its features' + (relatedServices.length ? ' and dependencies' : '') + '. Would you like to continue?',
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // remove related services (if any)
        // console.log(':: removeService:: relatedServices')
        // console.log(relatedServices)
        if (relatedServices.length) {
          for (let rs in relatedServices) {
            let rsUUID = relatedServices[rs]
            console.log(rsUUID)
            this.product.data.business.services[rsUUID].dependencies.splice(
              this.product.data.business.services[rsUUID].dependencies.indexOf(serviceUUID), 1
            )
            this.product.data.business.services[rsUUID].dependenciesDetails = {
              dependencies: this.product.data.business.services[rsUUID].dependencies
            }
          }
        }
        // remove its components
        let componentsToRemove = this.product.data.business.services[serviceUUID].components
        for (let comp in componentsToRemove) {
          this.$delete(this.product.data.business.components, componentsToRemove[comp])
        }
        // remove its groups (if any)
        if (this.product.data.business.groups) {
          console.log('looking for: ', serviceUUID)
          for (let groupId in this.product.data.business.groups.list) {
            console.log(groupId, this.product.data.business.groups.list[groupId].services)
            if (this.product.data.business.groups.list[groupId].services) {
              let serviceInGroupIndex = this.product.data.business.groups.list[groupId].services.indexOf(serviceUUID)
              if (serviceInGroupIndex >= 0) {
                // remove the service from the group
                this.product.data.business.groups.list[groupId].services.splice(serviceInGroupIndex, 1)
              }
            }
          }
        }
        // remove the service completely
        this.$delete(this.product.data.business.services, serviceUUID)
        // reset selection and close dialog
        this.dialogServicesUpdateSelection = null
        this.componentUpdate()
        // db.update
        this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE SERVICE: S:${serviceUUID}`, () => {})
        // console.log(':: removeService:: outcome():')
        // console.log(this.product.data)
      }).catch(() => {})
    },
    removeComponent (serviceUUID, componentUUID) {
      let productComponents = this.product.data.business.services[serviceUUID].components
      if (productComponents.length === 1) {
        return this.$q.dialog({
          title: 'Delete Service',
          message: 'Removing this feature will delete this service. Would you like to continue?',
          ok: this.$t('YES'),
          cancel: this.$t('NO')
        }).then(() => {
          // remove the service completely
          this.$delete(this.product.data.business.services, serviceUUID)
          // delete this.product.data.business.services[serviceUUID]
          // remove component from components hash
          // delete this.product.data.business.components[componentUUID]
          this.$delete(this.product.data.business.components, componentUUID)
          // reset selection and close dialog
          this.dialogServicesUpdateSelection = null
          this.componentUpdate()
          // db.update
          this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE SERVICE_LAST_COMPONENT: S:${serviceUUID}`, () => {})
        }).catch(() => {})
      }
      // remove component from services components array
      this.product.data.business.services[serviceUUID].components.splice(
        this.product.data.business.services[serviceUUID].components.indexOf(componentUUID), 1
      )
      // remove component from components hash
      // delete this.product.data.business.components[componentUUID]
      this.$delete(this.product.data.business.components, componentUUID)
      // we need to reset the icon to a different component
      if (this.product.data.business.services[serviceUUID].icon === componentUUID) {
        this.product.data.business.services[serviceUUID].icon = this.product.data.business.services[serviceUUID].components[0]
      }
      // refresh the "selected node"
      this.dialogServicesUpdateSelection = this.computeServiceProperties(serviceUUID)
      // db.update
      this.mutateProduct(this.product.id, this.product.data, `SIGNAL: SEND: REMOVE COMPONENT: S:${serviceUUID} C:${componentUUID}`, () => {})
    },
    selectPop (ref) {
      this.$refs[ref].show()
    },
    openPublicChannel () {
      openURL(this.productFullURI)
    },
    channelUUID () {
      try {
        return 'pn_' + this.product.data.uri
      } catch (e) {
        return null
      }
    },
    requestView (requestIndex, request) {
      this.dialogRequestIndex = requestIndex
      this.dialogRequestShow = true
      console.log(requestIndex, request)
    },
    loyaltyEnterPIN () {
      // if (!this.loyalty_enabled || !this.requests.length) return
      // reset value
      this.dialogRequestPinValue = ''
      this.dialogRequestPinShow = true
      // monitor when 4-numbers entered to automatically trigger check
      if (!this.dialogRequestPinValueTimer) {
        this.dialogRequestPinValueTimer = setInterval(() => {
          if (this.dialogRequestPinCheckingKeep && this.dialogRequestPinValue.length >= 4) {
            this.loyaltyCheckPIN()
          }
        }, 400)
      }
      this.loyaltyEnterPINFocus()
    },
    loyaltyEnterPINFocus () {
      // autofocus on the first input
      setTimeout(() => {
        document.getElementsByClassName('vue-pincode-input-wrapper')[0].firstChild.focus()
      }, 200)
    },
    loyaltyEnterPINHide () {
      this.dialogRequestPinValue = ''
      this.dialogRequestPinShow = false
      clearInterval(this.dialogRequestPinValueTimer)
      this.dialogRequestPinValueTimer = null
    },
    loyaltyCheckPIN () {
      console.log(':: loyaltyCheckPIN: Checking')
      // look into the request table and find a match
      for (let r in this.requests) {
        let req = this.requests[r]
        if (req.info.pin === parseInt(this.dialogRequestPinValue)) {
          console.log(':: loyaltyCheckPIN: Found', req)
          this.requestView(r, req)
          // reset / hide PIN form
          this.dialogRequestPinValue = ''
          if (!this.dialogRequestPinCheckingKeep) {
            setTimeout(this.loyaltyEnterPINHide, 1)
          }
          return
        }
      }
      console.log(':: loyaltyCheckPIN: Not found')
      // not found - try again
      this.dialogRequestPinChecking = false
      this.dialogRequestPinValue = ''
      this.$q.notify({
        message: 'Invalid PIN',
        detail: 'Loyalty Card',
        color: 'white',
        textColor: 'protect',
        position: 'top',
        icon: 'ion-close-circle',
        duration: 800
      })
      // this.channelRequestsClear()
    },
    loyaltyStatusUpdateProcess (stampNumber) {
      this.requests[this.dialogRequestIndex].status = 'completed'
      if (stampNumber !== false) {
        this.requests[this.dialogRequestIndex].info.stamps = stampNumber
      }
      // publish update
      this.channelRequestsPublish()
      setTimeout(() => {
        this.dialogRequestShow = false
        this.loyaltyEnterPINFocus()
      }, 200)
    },
    loyaltyStatusUpdate (stampNumber, isCarryover) {
      let isRewardMetaOptions = this.product.data.business.loyalty.rewardsMetaOptions.length
      // check if this "reward" requires additional details
      if ((isCarryover === false && stampNumber === false && isRewardMetaOptions) ||
        (!isCarryover && isRewardMetaOptions && stampNumber > this.product.data.business.loyalty.stamps)) {
        this.$q.dialog({
          title: 'Select Reward',
          preventClose: true,
          cancel: 'Cancel',
          options: {
            type: 'radio',
            model: 'reward_tall',
            items: this.product.data.business.loyalty.rewardsMetaOptions
          }
        }).then((data) => {
          // TODO: save the "reward" meta data somewhere
          // update
          this.loyaltyStatisticsLog('reward', data)
          this.loyaltyStatusUpdateProcess(stampNumber)
        }).catch(() => {
          // CANCELLED
          // this.$q.notify('Cancelled')
        })
      } else if (!isCarryover && stampNumber > this.product.data.business.loyalty.stamps) {
        this.loyaltyStatisticsLog('reward', null)
        this.loyaltyStatusUpdateProcess(stampNumber)
      } else {
        // process normally
        let currStamps = this.requests[this.dialogRequestIndex].info.stamps
        if (!currStamps) currStamps = 0

        if (stampNumber > this.product.data.business.loyalty.stamps) {
          // reward was not given
          // reward was carried over
          this.loyaltyStatisticsLog('carryover', 1)
        }
        this.loyaltyStatisticsLog('stamp', stampNumber - currStamps)
        this.loyaltyStatusUpdateProcess(stampNumber)
      }
    },
    loyaltyStatisticsLog (action, actionData) {
      let uid = this.requests[this.dialogRequestIndex].user.publicAddress
      let pid = this.product.id
      console.log(`:: LOYALTY.STAT: ${pid} ${uid} ${action} ${actionData}`)
      // publish
      axiosLIO.post('/nservice/stat', {
        product_id: pid,
        user_idx: uid,
        service: 'loyalty',
        action,
        action_data: actionData
      })
    },
    loyaltyStatusUseCarryover (carryoverNumber) {
      // confirm
      if (carryoverNumber > 1) {
        this.$q.dialog({
          title: 'Loyalty Reward',
          message: `Are you sure you want to give ${carryoverNumber} free items?`,
          ok: this.$t('YES'),
          cancel: this.$t('NO')
        }).then(() => {
          // delete
          this.loyaltyStatusApplyCarryover(carryoverNumber)
        }).catch(() => {
          // ignore
        })
      } else {
        this.loyaltyStatusApplyCarryover(carryoverNumber)
      }
    },
    loyaltyStatusApplyCarryover (carryoverNumber) {
      // console.log(':: LOYALTY.LOG: REWARD: USED CARRYOVER', carryoverNumber)
      this.loyaltyStatisticsLog('reward_carryover', carryoverNumber)
      if (this.requests[this.dialogRequestIndex].info.carryover) {
        this.requests[this.dialogRequestIndex].info.carryover = this.requests[this.dialogRequestIndex].info.carryover - carryoverNumber
      } else {
        this.requests[this.dialogRequestIndex].info.carryover = 0
      }
      if (this.requests[this.dialogRequestIndex].info.carryover < 0) {
        this.requests[this.dialogRequestIndex].info.carryover = 0
      }
      this.loyaltyStatusUpdate(false, false)
    },
    loyaltyStatusCarryover (stampNumber) {
      // generate a new card with a carryover (to give a free drink away)
      if (this.requests[this.dialogRequestIndex].info.carryover) {
        this.requests[this.dialogRequestIndex].info.carryover++
      } else {
        this.requests[this.dialogRequestIndex].info.carryover = 1
      }
      this.loyaltyStatusUpdate(stampNumber, true)
    },
    requestStatusUpdate (status) {
      this.requests[this.dialogRequestIndex].status = status
      if (status === 'finalize') {
        // remove it from the channel
        if (this.requests.length === 1) {
          this.requests = []
        } else {
          let _requests = this.requests
          _requests.splice(this.dialogRequestIndex, 1)
          this.requests = _requests
        }
      }
      // publish update
      this.channelRequestsPublish()
      setTimeout(() => {
        this.dialogRequestShow = false
      }, 200)
    },
    channelRequestsAdd (request) {
      console.log(':: channelRequestsAdd')
      if (request.action === 'clear') {
        console.log(':: CLEAR SIGNAL RECEIVED!')
        console.log(request)
        console.log(this.requests)
        if (request.item === 'loyalty_card') {
          // remove it from the array
          let requestChanges = false
          for (let r in this.requests) {
            let req = this.requests[r]
            if (request.user.publicAddress === req.user.publicAddress) {
              if (req.item === 'loyalty_card') {
                console.log(':: REMOVE REQUEST!')
                requestChanges = true
                this.requests.splice(r, 1)
              }
            }
          }
          if (requestChanges) {
            this.channelRequestsPublish()
          }
        }
        return
      }
      request.status = 'in-review'
      request.location = 'concierge'
      // create an ID for the service request
      let alphas = 'ABCDEFGHJKLMNPRSTXY'.split('')
      request.id = [
        'W', alphas[Math.floor(Math.random() * Math.floor(alphas.length))],
        Date.now().toString().substr(-2)
      ].join('')
      // add request
      this.requests.push(request)
      this.channelRequestsPublish()
    },
    channelRequestsClear () {
      this.requests = []
      this.channelRequestsPublish()
    },
    channelRequestsPublish () {
      this.product.data.channel.requests = this.requests
      this.channelPublish()
      this.mutateProduct(this.product.id, this.product.data, 'SIGNAL: SEND: ', () => {})
    },
    channelFetchUpdater () {
      // only fetch after 2 seconds of sequential multi-requests
      this.channelFetchTimer = setInterval(() => {
        if (this.settings_dev_presense) console.log(`:: Channel: Presense: Checking: Last=${this.channelFetchActiveClientsRequestsLastCount}, Now=${this.channelFetchActiveClientsRequestsCount}`)
        // are the requests count different
        if (this.channelFetchActiveClientsRequestsLastCount < this.channelFetchActiveClientsRequestsCount) {
          this.channelFetchActiveClientsRequestsLastCount = this.channelFetchActiveClientsRequestsCount
          return
        }
        if (this.channelFetchActiveClientsRequestsCount > 0 && this.channelFetchActiveClientsRequestsCount === this.channelFetchActiveClientsRequestsLastCount) {
          this.channelFetchActiveClientsRequestsLastCount = 0
          this.channelFetchActiveClientsRequestsCount = 0
          this.channelFetchActiveClientsRequest()
        }
      }, 2000)
    },
    channelOnlineVerifier () {
      // we need to force a reload
      // if the _current_ user is not in the list of clients
      // check every 1 minute now
      this.channelOnlineTimer = setInterval(() => {
        if (this.settings_dev_presense) console.log(`:: Channel: Presense: AmIOnline?`)
        if (!this.amIOnline) {
          if (this.settings_dev_presense) console.log(`:: Channel: Presense: AmIOnline: NO`)
          if (this.settings_dev_presense) console.log(`:: Channel: Presense: Restart`)
          // refresh the connection
          this.channelDisconnect()
          this.channelInit()
        }
      }, 60 * 1000)
    },
    channelFetchActiveClients () {
      if (this.channelFetchActiveClientsRequestsFirstTime) {
        this.channelFetchActiveClientsRequestsFirstTime = false
        if (this.settings_dev_presense) console.log(`:: Channel: Presense: Requst Trigger: FirstTime (0-delay)`)
        this.channelFetchActiveClientsRequest()
        return
      }
      this.channelFetchActiveClientsRequestsCount++
      if (this.settings_dev_presense) console.log(`:: Channel: Presense: Request Trigger: @${this.channelFetchActiveClientsRequestsCount}`)
    },
    channelFetchActiveClientsRequest () {
      this.channelFetchActiveClientsRequestActive = true
      const randId = this.$guid.generate()
      if (this.settings_dev_presense) console.log(`:: Channel: Presense: HERENOW: Requested #${randId}`)
      this.pn.hereNow({
        channels: [this.channelUUID()],
        includeUUIDs: true,
        includeState: true
      }, (status, response) => {
        if (this.settings_dev_presense) console.log(`:: Channel: Presense: HERENOW: Responded #${randId}`)
        if (this.settings_dev_presense) console.log(':: Channel: Presense: HERENOW: d: ', response)
        if (this.settings_dev_presense) console.log(':: Channel: Presense: --------------------------------------')
        this.clients = response.channels[this.channelUUID()].occupants
        this.channelFetchActiveClientsRequestActive = false
      })
    },
    channelDisconnect () {
      if (this.channelDisconnectionRequested) return
      this.channelDisconnectionRequested = true
      this.$q.notify({
        message: 'Disconnecting channel...',
        color: 'white',
        position: 'bottom',
        duration: 200
      })
      // clear timers
      clearInterval(this.channelOnlineTimer)
      clearInterval(this.channelFetchTimer)
      // unsubscribe
      if (this.pn) {
        // this.pn.unsubscribe({
        //   channels: [this.channelUUID()]
        // })
        // console.log(':: Channel: Presense: Unsubscribed')
        this.pn.stop()
        if (this.settings_dev_presense) console.log(':: Channel: Presense: Stopped')
        if (this.settings_dev_presense) console.log(':: Channel: Presense: Disconnected')
      }
      this.pn = null
    },
    channelInit () {
      if (!this.pn) {
        console.log(':: Channel: INIT')
        this.pn = new PubNub({
          subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
          publishKey: 'pub-c-4a7e4814-55a0-4e5f-98d7-eba6d8e92cd3',
          uuid: this.product.data.uri,
          ssl: true,
          autoNetworkDetection: true,
          presenceTimeout: 60
        })
        console.log(':: Channel: SUBSCRIBING...', [this.channelUUID(), this.channelUUID() + '.requests'])
        this.pn.subscribe({
          channels: [this.channelUUID(), this.channelUUID() + '.requests'],
          withPresence: true
        })
        //
        console.log(':: Channel: SETSTATE')
        console.log(':: Channel: SETSTATE UUID: ', this.product.data.uri)
        console.log(':: Channel: SETSTATE CHANNELS: ', this.channelUUID())
        this.pn.setState({
          state: {
            observing: true,
            role: 'admin'
          },
          uuid: this.product.data.uri,
          channels: [this.channelUUID(), this.channelUUID() + '.requests']
        }, function (r) {
          console.log(':: channelSetState ', r)
        })
        //
        console.log(':: Channel: LISTENING...')
        console.log('## pn.listen >> presence')
        console.log('## pn.listen >> presence >> ADMIN UUID: ', this.product.data.uri)
        this.pn.addListener({
          state: (s) => {
            console.log('state', s)
          },
          message: (m) => {
            console.log(':: Channel: [', m.channel, '] UPDATE: ')
            if (m.channel.split('.').pop() === 'requests') {
              console.log(m)
              this.channelRequestsAdd(m.message)
            } else {
              console.log(m)
              // check if we have a status change (possibly from another user and/or admin)
              if (m.message.component && m.message.component.uuid) {
                // we have component status updates
                console.log(':: we have component status updates')
                try {
                  let component = this.decodeUUID(m.message.component.uuid)
                  this.product.data.business.components[component].status = m.message.component.status
                  this.product.data.business.components[component].updated = new Date().getTime()
                } catch (e) {}
              }
            }
          },
          presence: (p) => {
            // handle presence
            // var action = p.action // Can be join, leave, state-change or timeout
            // var channelName = p.channel // The channel for which the message belongs
            // var occupancy = p.occupancy // No. of users connected with the channel
            // var state = p.state // User State
            // var channelGroup = p.subscription //  The channel group or wildcard subscription match (if exists)
            // var publishTime = p.timestamp // Publish timetoken
            // var timetoken = p.timetoken  // Current timetoken
            // var uuid = p.uuid // UUIDs of users who are connected with the channel
            if (this.settings_dev_presense) console.log(':: Channel: Presense: Change detected: ', p.action)
            this.channel.presence = p
            this.channel.presense_count = p.occupancy
            // let's call fetch as long as there are no consequence of calls
            this.channelFetchActiveClients()
          },
          status: (statusEvent) => {
            if (statusEvent.category === 'PNConnectedCategory') {
              if (this.settings_dev_presense) console.log(':: Channel: Presense: Connected')
              this.channelFetchActiveClients()
            }
          }
        })
        this.channelFetchUpdater()
        this.channelOnlineVerifier()
      }
    },
    encodeUUID (uuid) {
      // Remove hyphens and convert to binary
      const hex = uuid.replace(/-/g, '')
      const bin = Buffer.from(hex, 'hex')
      // Convert binary to Base64
      return bin.toString('base64').replace(/=/g, '')
    },
    decodeUUID (uuidEncoded) {
      // Decode from Base64
      const bin = Buffer.from(uuidEncoded, 'base64')
      // Convert binary to hex
      let hex = bin.toString('hex')
      // Re-insert hyphens to get the UUID in standard format
      return hex.substring(0, 8) + '-' + hex.substring(8, 12) + '-' + hex.substring(12, 16) + '-' + hex.substring(16, 20) + '-' + hex.substring(20)
    },
    channelPublish (component) {
      // we need to have specific channel updates
      if (component) {
        component.uuid = this.encodeUUID(component.uuid)
      }
      let channel = {
        id: this.product.data.channel.id,
        online: this.product.data.channel.online,
        orders: this.product.data.channel.orders,
        loyalty_card: this.product.data.channel.loyalty_card,
        qrcode_ref: this.product.data.channel.qrcode_ref || this.product.data.qrcode_ref,
        requests: this.requests || [],
        component: component || null
      }
      // overwrite channel
      this.channel = channel
      this.product.data.channel = channel
      console.log(':: Channel: PUBLISHING...', this.channelUUID())
      console.log(this.product.data.channel)
      this.pn.publish({
        channel: this.channelUUID(),
        sendByPost: true,
        storeInHistory: true,
        message: this.product.data.channel
      }, (status, response) => {
        if (status.error) {
          console.log(status)
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
        }
      })
    },
    remove () {
      // confirm
      this.$q.dialog({
        title: this.$t(this.getEcosystemLabel('DELETE.TITLE_QUERY')),
        message: this.$t(this.getEcosystemLabel('DELETE.MESSAGE_QUERY')),
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
        this.archive()
      }).catch(() => {
        // ignore
      })
    },
    archiveProduct (id, done) {
      console.log('🦋 [product] archive')
      axiosLIO.post('/product/archive', {
        id
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    mutateProduct (id, payload, audit, done) {
      console.log('🦋 [product] mutate')
      axiosLIO.post('/product/mutate', {
        id,
        payload: JSON.stringify(payload),
        audit
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    archive () {
      this.dialogRemovingShow = true
      this.archiveProduct(this.product.id, () => {
        setTimeout(() => {
          this.dialogRemovingShow = false
          // reset local storage to force a reload of data
          this.$store.state.app.products.list = []
          this.$router.replace('/business')
        }, 400)
      })
    },
    shortlinkCreate (cb) {
      console.log(':: shortlinkCreate')
      // 1) shorten url
      const url = encodeURI(this.productFullURI.replace('http://localhost:8080', 'https://mywings.app'))
      const BitlyClient = require('bitly').BitlyClient
      const bitly = new BitlyClient('329490bd2c35f2c747df8f26cbcd88cd24972c22', {
        domain: 'ltsbtrf.ly'
      })
      bitly
        .shorten(url)
        .then((result) => {
          // 2) save shortened url
          this.shortlinkSave(result.link)
          if (cb) cb()
        }).catch((error) => {
          console.error(error)
        })
    },
    shortlinkSave (shortLink) {
      console.log(':: shortlinkSave')
      this.product.data.shortlink = shortLink
      this.channel.shortlink = this.product.data.shortlink
      this.mutateProduct(this.product.id, this.product.data, `IDS.SHORTLINK: ${this.product.data.shortlink}`, () => {})
      return true
    },
    qrcodeCreate () {
      // startover
      this.channel.qrcode_ref = null
      // start the creation process
      console.log(':: qrcodeCreate')
      // 1) generate qr code
      let qrcodeConfig = {
        body: 'circle-zebra-vertical',
        eye: 'frame1',
        eyeBall: 'ball1',
        bodyColor: '#000000',
        bgColor: '#ffffff',
        logo: 'https://res.cloudinary.com/letsbutterfly/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1672307562/wings-app/assets/WINGS_icon-wide-contrast.jpg',
        logoMode: 'clean',
        erf1: ['fh'],
        erf3: ['fh', 'fv'],
        eye1Color: '#53585F',
        eye2Color: '#53585F',
        eye3Color: '#53585F',
        eyeBall1Color: '#FF0091',
        eyeBall2Color: '#FF0091',
        eyeBall3Color: '#FF0091',
        brf1: ['fh'],
        brf3: ['fh', 'fv']
      }
      // if product is part of a venue, check and use venue qrcode config
      let venueDetails = this.getVenue()
      if (venueDetails) {
        qrcodeConfig = venueDetails.qrcode
      }
      this.$axios.get('https://qrcode-monkey.p.rapidapi.com/qr/custom', {
        headers: {
          'content-type': 'application/octet-stream',
          'x-rapidapi-host': 'qrcode-monkey.p.rapidapi.com',
          'x-rapidapi-key': 'ae7bf8867fmsh1b89822b326e4cep1ec9eejsnfa0566b242f6'
        },
        params: {
          size: 1000,
          file: 'svg',
          config: qrcodeConfig,
          data: encodeURI(this.product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs'))
        }
      }).then((response) => {
        console.log(response)
        this.channel.qrcode_blob = 'data:image/svg+xml;utf8,' + response.data
        // 2) upload data to cloudinary
        this.qrcodeSave(response.data)
      }).catch((error) => {
        console.log(error)
      })
    },
    qrcodeSave (svg) {
      console.log(':: qrcodeSave')
      let self = this
      const blob = new Blob([svg], {type: 'image/svg+xml'})
      let xhr = new XMLHttpRequest()
      let fd = new FormData()
      xhr.open('POST', 'https://api.cloudinary.com/v1_1/letsbutterfly/upload', true)
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      xhr.upload.addEventListener('progress', function (e) {
        var progress = Math.round((e.loaded * 100.0) / e.total)
        console.log(`fileuploadprogress data.loaded: ${e.loaded}, data.total: ${e.total}, progress: ${progress}`)
      })

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText)
          console.log(response)
          // update data and mutate
          let qrcodeRef = response.secure_url
          self.channel.qrcode_ref = qrcodeRef
          self.product.data.qrcode_ref = qrcodeRef
          self.mutateProduct(self.product.id, self.product.data, `IDS.QRCODE_REF: ${self.product.data.qrcode_ref}`, () => {})
          return true
        } else {
          console.log(e)
        }
      }

      let tags = window.location.host.replace('.app', '')
      if (tags.indexOf(':') >= 0) {
        tags = 'local-dev'
      }

      fd.append('upload_preset', 'wings-app-499472be-0966-4123-9f82-9127026c51b3')
      fd.append('tags', tags)
      fd.append('file', blob)
      xhr.send(fd)
    },
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `qrcode_${this.product.data.uri.split('-')[0]}.png`)
      document.body.appendChild(link)
      link.click()
      this.dialogQRCodeDownloading = false
    },
    qrcodeDownload () {
      this.dialogQRCodeDownloading = true
      this.$axios.get(this.channel.qrcode_ref.replace('.svg', '.png'), {
        responseType: 'arraybuffer'
      }).then(response => {
        this.forceFileDownload(response)
      }).catch(() => {
        console.log('error occured')
        this.dialogQRCodeDownloading = false
      })
    },
    qrcode () {
      if (!this.channel.shortlink) {
        this.channel.shortlink = this.product.data.shortlink
      }
      if (!this.channel.qrcode_ref) {
        this.channel.qrcode_ref = this.product.data.qrcode_ref
      }
      this.dialogQRCodeShow = !this.dialogQRCodeShow
      if (!this.product.data.shortlink) {
        this.shortlinkCreate(() => {
          this.qrcodeCreate()
        })
      } else if (!this.channel.qrcode_ref) {
        this.qrcodeCreate()
      }
    },
    datafieldsInit () {
      this.datagroups.forEach((g, i) => {
        g.datafields.forEach((f, j) => {
          // initialize values
          if (f.valueType) {
            if (f.valueType.name === 'Boolean') {
              console.log(`this.datagroups[${i}].datafields[${j}].value`)
              this.datagroups[i].datafields[j].value = {
                option: 0,
                options: [{
                  bTrue: true,
                  label: this.$t('YES')
                }, {
                  bTrue: false,
                  label: this.$t('NO')
                }]
              }
            }
          }
          // initialize option
          if (typeof this.product.data.channel[f.id] === 'undefined') {
            this.product.data.channel[f.id] = this.datagroups[i].datafields[j].value.option || 0
          } else {
            this.datagroups[i].datafields[j].value.option = this.product.data.channel[f.id]
          }
          // create signals
          this.datagroups[i].datafields[j].signal = {
            // create update method
            update: (option) => {
              this.product.data.channel[f.id] = option
              this.channelPublish()
              this.mutateProduct(this.product.id, this.product.data, `SIGNAL.${f.id}: ${this.product.data.channel[f.id]}`, () => {})
            },
            // create descriptor
            bCheck: () => {
              let bTrue = this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].bTrue
              return (bTrue === true || bTrue === false) ? bTrue : null
            },
            descriptor: () => {
              try {
                return this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].label
              } catch (e) {
                return null
              }
            }
          }
        })
      })
      console.log('## end init')
      this.datafieldsInitialized = true
    },
    checkOptionDependency (option) {
      if (option.dependsOn) {
        try {
          return this.product.data.channel[option.dependsOn] === 0
        } catch (er) {}
      }
      return true
    },
    dialogItemAdjustFit (percentage = 80) {
      let innerHeight = window.innerHeight
      let dialogItemContent = document.querySelector('.dialogItem .modal-content')
      if (dialogItemContent) {
        try {
          dialogItemContent.style.setProperty('height', `${(innerHeight * percentage) / 100}px`, 'important')
        } catch (err) {}
      }
    }
  }
}
</script>
<style lang="stylus">
@import '../css/products-business.edit'
</style>
<style lang="stylus" scoped>
@import '~variables'
@import '../css/products-business.edit_scoped'
</style>
